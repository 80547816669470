<template>
  <section class="block-quote">
    <div class="block-quote__divider"></div>
    <blockquote>
      <div v-html="block.body"></div>
      <footer v-html="block.cite"></footer>
    </blockquote>
    <div class="block-quote__divider"></div>
  </section>
</template>

<script>
export default {
  name: 'BlockQuote',
  props: {
    block: [Array, Object]
  }
};
</script>

<style lang="postcss">
.block-quote {
  @apply max-w-mobile mx-auto;
  @apply relative;
  &__divider {
    height: 60px;
    width: 1px;
    @apply bg-brand-darkerBlue;
    @apply mx-auto;
    @apply my-12;
    @screen sm {
      height: 100px;
    }
  }
  blockquote {
    @apply text-center text-brand-darkerBlue;
    @screen sm {
      @apply max-w-6xl;
      @apply mx-auto;
    }
    p {
      &:before {
        content: '“';
      }
      &:after {
        content: '”';
      }
      @apply font-ginto-black;
      font-size: calc(16px + (40 - 16) * (100vw - 640px) / (1920 - 640));
    }
    footer {
      @apply mt-8;
      @apply font-ginto-regular;
      font-size: calc(8px + (26 - 8) * (100vw - 640px) / (1920 - 640));
      @screen sm {
        @apply mt-16;
      }
    }
  }
}
</style>
