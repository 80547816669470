<template>
  <section class="block-option-cards" :class="[background]">
    <div class="block-option-cards__wrapper">
      <div class="block-option-cards__tab-group">
        <div
          class="block-option-cards__tab-group__item"
          v-for="(title, index) in tabTitles"
          :key="`tab-title-${index}`"
          @click="currentIndex = index"
          :class="[currentIndex == index ? 'active' : '']"
        >
          <h4>{{ title }}</h4>
        </div>
      </div>
      <div class="block-option-cards__container">
        <div class="block-option-cards__group">
          <div
            class="block-option-cards__group__item"
            v-for="(option, index) in block.optionGroup"
            :key="`option-card-${index}`"
            @mouseover="
              [
                !getIsMobile ? (howToApplyIndex = index) : '',
                !getIsMobile ? (hover = true) : ''
              ]
            "
            @mouseout="
              [
                !getIsMobile ? (howToApplyIndex = null) : '',
                !getIsMobile ? (hover = false) : ''
              ]
            "
            :class="[
              currentIndex == index ? 'active' : '',
              { 'animate--slide-up': intersected }
            ]"
            :style="`animation-delay: ${index * 250}ms`"
          >
            <div class="block-option-cards__group__item__icon">
              <img :src="option.icon" alt="Icon" />
            </div>
            <div class="block-option-cards__group__item__heading">
              <h3 v-html="option.title"></h3>
              <h4
                v-html="
                  howToApplyIndex === index && option.price
                    ? 'How to apply'
                    : option.price
                "
              ></h4>
            </div>
            <div class="block-option-cards__group__item__container">
              <ul
                :class="[
                  howToApplyIndex === index && hover ? 'hidden' : 'block'
                ]"
              >
                <li v-if="option.fundingFocus">
                  <h5>Funding focus</h5>
                  <p>{{ option.fundingFocus }}</p>
                </li>
                <li v-if="option.fundingAreas">
                  <h5>Funding areas</h5>
                  <p>{{ option.fundingAreas }}</p>
                </li>
                <li v-if="false">
                  <h5>Exclusions</h5>
                  <p>{{ option.exclusions }}</p>
                </li>
              </ul>
              <div
                class="message"
                v-html="option.howToApply"
                :class="[
                  howToApplyIndex == index && hover ? 'block' : 'hidden'
                ]"
              ></div>
              <button
                class="read-more"
                @click="[(howToApplyIndex = index), (hover = !hover)]"
              >
                <div v-if="!hover">
                  <span>Read More</span>
                  <IconPlus />
                </div>
                <div v-else><span>Read Less</span> <IconMinus /></div>
              </button>
            </div>
            <div class="block-option-cards__group__item__cta">
              <CtaItem
                :label="option.ctaLabel"
                :link="`${option.ctaLink}`"
                :bubble="true"
                colour="black"
                :disabled="option.disabled"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { background } from '@/mixins';

import IconPlus from '@/components/Icon/IconPlus';
import IconMinus from '@/components/Icon/IconMinus';
import CtaItem from '@/components/Cta/CtaItem';

export default {
  name: 'BlockOptionCards',
  components: {
    IconPlus,
    IconMinus,
    CtaItem
  },
  props: {
    block: [Array, Object]
  },
  data: () => {
    return {
      howToApplyIndex: null,
      hover: false,
      currentIndex: 0,
      intersectionOptions: {
        root: null,
        threshold: 0.25
      },
      observer: null,
      intersected: false
    };
  },
  mixins: [background],
  computed: {
    ...mapGetters(['getIsMobile']),
    tabTitles() {
      return this.block.optionGroup.map(item => item.title);
    }
  },
  methods: {
    intersectionCallback(entries) {
      const entry = entries[0];
      if (entry.intersectionRatio > 0) {
        this.observer.unobserve(entry.target);
        this.intersected = true;
      }
    },
    initObserver() {
      this.observer = new IntersectionObserver(
        this.intersectionCallback,
        this.intersectionOptions
      );
      this.observer.observe(this.$el);
    }
  },
  mounted() {
    this.initObserver();
  }
};
</script>

<style lang="postcss">
@import '../../styles/animate.css';

.block-option-cards {
  &__wrapper {
    @screen sm {
      transform: translateY(-5vh);
    }
    @screen xl {
      transform: translateY(-15vh);
    }
  }
  /* @apply py-16; */
  @screen xxl {
    /* @apply py-32; */
  }
  &__tab-group {
    border-radius: 8px;
    @apply bg-brand-white;
    @apply max-w-max mx-auto;
    @apply mb-8;
    @apply flex;
    @screen sm {
      @apply hidden;
    }
    &__item {
      @apply flex-grow;
      @apply py-10;
      @apply opacity-25;
      @apply bg-brand-white;
      @apply border-l border-r border-brand-border;
      transition-property: background;
      transition-duration: 250ms;
      transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
      &:first-of-type {
        border-radius: 8px 0px 0px 8px;
        @apply border-l-0;
      }
      &:last-of-type {
        border-radius: 0px 8px 8px 0px;
        @apply border-l-0;
      }
      h4 {
        @apply text-brand-black text-center;
        font-size: calc(12px + (12 - 12) * (100vw - 640px) / (1920 - 640));
      }
      &.active {
        @apply opacity-100;
        @apply bg-green-600;
        h4 {
          @apply text-brand-white;
        }
      }
    }
  }
  &__container {
    @apply flex justify-center;
  }
  &__group {
    @apply max-w-max;
    @apply flex flex-col;
    @screen sm {
      @apply flex-row justify-evenly;
      @apply w-full max-w-full;
    }
    &__item {
      @apply opacity-0;
      @apply p-16;
      @apply bg-brand-white;
      border-radius: 8px;
      @apply flex-col;
      @apply mb-8;
      @apply cursor-pointer;
      transition-property: background;
      transition-duration: 250ms;
      transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
      @apply hidden;
      &.active {
        @apply flex;
      }
      &:hover {
        @screen sm {
          @apply bg-brand-light-blue;
          ul {
            @apply hidden;
          }
        }
      }
      @screen sm {
        @apply flex;
        @apply mx-4;
        @apply mb-0;
        @apply w-1/3;
        @apply max-w-2xl;
      }
      @screen lg {
        @apply max-w-4xl;
      }
      &__icon {
        @apply mb-8;
        height: 100px;
        @apply flex items-center justify-center;
      }
      &__heading {
        min-height: 60px;
        h3 {
          @apply font-ginto-black text-center;
          @apply leading-snug;
          font-size: calc(28px + (28 - 28) * (100vw - 640px) / (1920 - 640));
        }
        h4 {
          @apply font-ginto-regular text-center;
          @apply leading-none;
          font-size: calc(28px + (28 - 28) * (100vw - 640px) / (1920 - 640));
        }
      }
      &__container {
        @apply mt-8;
        @apply pt-8;
        @screen sm {
          min-height: 400px;
        }
        ul {
          li {
            @apply font-ginto-regular;
            @apply py-4;
            @apply border-b border-solid border-brand-grey;
            font-size: calc(16px + (16 - 16) * (100vw - 640px) / (1920 - 640));
            &:first-of-type {
              @apply border-t;
            }
            h5 {
              @apply font-ginto-bold;
              font-size: calc(
                12px + (16 - 12) * (100vw - 640px) / (1920 - 640)
              );
            }
            p {
              font-size: calc(
                12px + (16 - 12) * (100vw - 640px) / (1920 - 640)
              );
            }
          }
        }
        .read-more {
          @apply py-8;
          @apply flex;
          @apply mx-auto;
          div {
            @apply flex items-center;
            span {
              @apply font-ginto-bold;
              @apply mr-4;
              font-size: calc(
                12px + (12 - 12) * (100vw - 640px) / (1920 - 640)
              );
            }
          }
          @apply appearance-none outline-none;

          @screen sm {
            @apply hidden;
          }
        }
        .message {
          @apply py-4;
          @apply border-t border-solid border-brand-grey;
          p {
            @apply pb-8;
            font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
            &:last-of-type {
              @apply pb-0;
            }
            a {
              @apply border-b border-brand-black;
              @apply pb-1;
            }
          }
        }
      }
      &__cta {
        @apply max-w-xl;
        @apply mx-auto;
        @apply mt-auto;
        .cta-item {
          @apply justify-center;
          a {
            font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
          }
        }
      }
    }
  }
}

.tab-group {
}
</style>
