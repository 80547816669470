<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    height="160"
    viewBox="0 0 160 160"
  >
    <g transform="translate(-922.324 -6104.324)">
      <g
        transform="translate(922.324 6104.324)"
        fill="none"
        stroke="#fff"
        stroke-width="2"
      >
        <circle cx="80" cy="80" r="80" stroke="none" />
        <circle cx="80" cy="80" r="79" fill="none" />
      </g>
      <g transform="translate(1031.324 6158.324) rotate(90)" fill="none">
        <path
          d="M23.388,4.621a3,3,0,0,1,5.223,0l20.858,36.9A3,3,0,0,1,46.858,46H5.142A3,3,0,0,1,2.53,41.524Z"
          stroke="none"
        />
        <path
          d="M 26 5.096843719482422 C 25.82456970214844 5.096843719482422 25.38855934143066 5.146335601806641 25.12944030761719 5.604785919189453 L 4.271141052246094 42.5079345703125 C 4.016078948974609 42.95919418334961 4.190948486328125 43.35480499267578 4.277969360351562 43.50395584106445 C 4.364990234375 43.65309524536133 4.623359680175781 43.99999618530273 5.141700744628906 43.99999618530273 L 46.85829925537109 43.99999618530273 C 47.37664031982422 43.99999618530273 47.635009765625 43.65309524536133 47.72203063964844 43.50395584106445 C 47.80905151367188 43.35480499267578 47.98392105102539 42.95919418334961 47.72885894775391 42.5079345703125 L 26.87056922912598 5.604793548583984 C 26.61144065856934 5.146335601806641 26.17543029785156 5.096843719482422 26 5.096843719482422 M 25.99999618530273 3.096847534179688 C 27.01874160766602 3.096847534179688 28.03749084472656 3.604789733886719 28.61169052124023 4.620674133300781 L 49.46998977661133 41.52382659912109 C 50.60034942626953 43.52370452880859 49.15552139282227 45.99999618530273 46.85829925537109 45.99999618530273 L 5.141700744628906 45.99999618530273 C 2.844478607177734 45.99999618530273 1.399650573730469 43.52370452880859 2.530010223388672 41.52382659912109 L 23.38830947875977 4.620674133300781 C 23.96250534057617 3.604789733886719 24.98125076293945 3.096847534179688 25.99999618530273 3.096847534179688 Z"
          stroke="none"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconPlay',
  props: {
    width: Number
  }
};
</script>

<style lang="postcss" scoped>
svg {
}
</style>
