export default {
  computed: {
    nextcolour() {
      const nextColour = this.block.options.nextColour
        ? this.block.options.nextColour
        : 'darkerBlue';
      return `bg-brand-${nextColour}`;
    }
  }
};
