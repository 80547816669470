function getTitle(vm) {
  const { title } = vm.$options;
  if (title) {
    return typeof title === 'function' ? title.call(vm) : title;
  }
}

export default {
  watch: {
    page(val) {
      this.apiAmendTitle(val);
    },
    content(val) {
      this.apiAmendTitle(val);
    }
  },
  created() {
    const title = getTitle(this);
    if (title) {
      document.title = title + ' – Westminster Foundation';
    }
  },
  methods: {
    apiAmendTitle(val) {
      if (
        val != null &&
        this.$route.name !== 'Home' &&
        this.$route.name !== 'NewsCover' &&
        this.$route.name !== 'Reports'
      ) {
        if (val.title) {
          document.title = val.title + ' – Westminster Foundation';
        }
      }
    }
  }
};
