<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="162.004"
    height="126.384"
    viewBox="0 0 162.004 126.384"
  >
    <g transform="translate(-1215.341 -357.65)">
      <g transform="translate(1263.295 357.65)">
        <path
          d="M1413.151,453.622a24.707,24.707,0,0,0,2.067-1.645.321.321,0,0,0,.014-.464q-.727-.756-1.373-1.551a.311.311,0,0,0-.453-.037,21.308,21.308,0,0,1-1.8,1.434c-5.33,3.49-8.88,6.94-8.88,12.431,0,4.4,2.7,6.4,5.21,6.4,2.7,0,5.572-2.243,5.572-6.394l0-.709a28.3,28.3,0,0,0-.882-7,.333.333,0,0,0-.515-.18q-.944.681-1.685,1.3a.625.625,0,0,0-.205.615,25.6,25.6,0,0,1,.547,5.258l0,.709c0,2.515-1.468,3.661-2.833,3.661-1.5,0-2.47-1.437-2.47-3.661C1405.468,460,1407.467,457.344,1413.151,453.622Z"
          transform="translate(-1383.118 -416.603)"
          fill="#fff"
        />
        <path
          d="M1412.626,411.658l-1.967.243a.319.319,0,0,0-.26.425,30.556,30.556,0,0,1-24.156,40.4,30.576,30.576,0,0,1-35.164-30.194,30.263,30.263,0,0,1,2.68-12.552,26.512,26.512,0,0,1,19.6,14.433.307.307,0,0,0,.46.113c.494-.366,1-.721,1.513-1.068a.622.622,0,0,0,.209-.8,29.268,29.268,0,0,0-22.421-15.536l-.31-.038a.932.932,0,0,0-.95.514l-.137.28a32.961,32.961,0,0,0-3.382,14.653,33.276,33.276,0,1,0,66.526-1.316,32.945,32.945,0,0,0-1.675-9.2A.527.527,0,0,0,1412.626,411.658Z"
          transform="translate(-1348.34 -389.256)"
          fill="#fff"
        />
        <path
          d="M1434.055,422.6q.324-.917.706-1.809a.6.6,0,0,0-.181-.713c-4.078-3.259-6.661-7.709-6.661-11.611,0-2.515,1.468-3.66,2.832-3.66s2.83,1.146,2.83,3.66a12.434,12.434,0,0,1-1.855,6.146.615.615,0,0,0,.022.669,18.2,18.2,0,0,0,1.311,1.614.331.331,0,0,0,.515-.041,15.565,15.565,0,0,0,2.746-8.388c0-4.157-2.87-6.4-5.569-6.4s-5.572,2.243-5.572,6.4c0,5,3.185,10.417,8.384,14.285A.321.321,0,0,0,1434.055,422.6Z"
          transform="translate(-1397.474 -386.055)"
          fill="#fff"
        />
        <path
          d="M1364.991,373.878a.5.5,0,0,0,.509-.212,30.557,30.557,0,0,1,51.753,2.157,29.005,29.005,0,0,0-23.668,28.318l0,.7c0,4.157,2.871,6.4,5.572,6.4,2.509,0,5.21-2,5.21-6.4,0-3.9-1.79-6.762-4.716-9.335a.317.317,0,0,0-.5.123q-.377,1.016-.664,2.071a.637.637,0,0,0,.174.615,8.589,8.589,0,0,1,2.967,6.526c0,2.224-.97,3.661-2.47,3.661-1.365,0-2.833-1.146-2.833-3.667l0-.7a26.291,26.291,0,0,1,23.2-25.9l1.2-.149a.507.507,0,0,0,.393-.728l-.229-.467a33.945,33.945,0,0,0-30.615-19.239,33.035,33.035,0,0,0-27.683,15.467.254.254,0,0,0,.174.388C1363.326,373.591,1364.367,373.752,1364.991,373.878Z"
          transform="translate(-1357.43 -357.65)"
          fill="#fff"
        />
      </g>
      <g transform="translate(1215.341 437.295)">
        <g transform="translate(0 0)">
          <path
            d="M1241.6,580.063l-5.18,19.19h-4.085l-3.975-15.571-3.975,15.571h-4.113l-4.934-19.19h3.7L1222.469,595l3.728-14.941h4.413l3.7,14.914,3.592-14.914Z"
            transform="translate(-1215.341 -579.516)"
            fill="#fff"
          />
          <path
            d="M1303.011,601.933h-9.731c.3,2.386,2,3.728,4.853,3.728a7.554,7.554,0,0,0,4.358-1.37v2.769a8.455,8.455,0,0,1-5.017,1.453c-4.632,0-7.675-2.852-7.675-7.373,0-4.223,2.6-7.238,7.018-7.238,3.811,0,6.2,2.248,6.2,6.25Zm-9.649-2.138h6.578a2.893,2.893,0,0,0-3.124-3.126A3.27,3.27,0,0,0,1293.362,599.795Z"
            transform="translate(-1262.952 -588.364)"
            fill="#fff"
          />
          <path
            d="M1333,606.868v-2.907a8.224,8.224,0,0,0,4.88,1.837c1.507,0,2.412-.576,2.412-1.672,0-.74-.411-1.261-1.591-1.618l-2.275-.658c-2.3-.631-3.4-1.92-3.4-3.811,0-2.467,2.056-4.14,5.374-4.14a8.517,8.517,0,0,1,4.632,1.316v2.852a7.4,7.4,0,0,0-4.413-1.453c-1.48,0-2.194.466-2.194,1.315,0,.713.494,1.207,1.508,1.48l2.248.576c2.522.631,3.564,2.057,3.564,4.03,0,2.686-2.22,4.5-5.51,4.5A8.81,8.81,0,0,1,1333,606.868Z"
            transform="translate(-1290.576 -588.364)"
            fill="#fff"
          />
          <path
            d="M1376.741,589.989h-3.618v10.965h-3.4V589.989h-3.262v-2.824h3.262v-3.537l3.4-.906v4.442h3.618Z"
            transform="translate(-1311.974 -581.217)"
            fill="#fff"
          />
          <path
            d="M1421.355,599.163V608.1h-3.4v-8.334c0-1.946-.768-2.961-2.3-2.961a2.587,2.587,0,0,0-2.6,2.741V608.1H1409.7v-8.525c0-1.754-.851-2.769-2.359-2.769s-2.548,1.316-2.575,3.1v8.2h-3.4V594.313h3.373v2.3a4.1,4.1,0,0,1,3.947-2.715,4.02,4.02,0,0,1,3.894,2.577,4.464,4.464,0,0,1,4.166-2.577C1419.628,593.9,1421.355,595.9,1421.355,599.163Z"
            transform="translate(-1334.296 -588.364)"
            fill="#fff"
          />
          <path
            d="M1466.243,580.464a1.918,1.918,0,0,1,2.029-1.918,1.894,1.894,0,0,1,2,1.918,1.916,1.916,0,0,1-2,1.946A1.94,1.94,0,0,1,1466.243,580.464Zm.329,4.03h3.4v13.789h-3.4Z"
            transform="translate(-1375.779 -578.546)"
            fill="#fff"
          />
          <path
            d="M1500.024,599.465V608.1h-3.4v-7.9c0-2.3-.794-3.4-2.686-3.4-2.029,0-3.152,1.48-3.152,3.892v7.4h-3.4V594.313h3.373v2.193a4.625,4.625,0,0,1,4.468-2.6C1498.187,593.9,1500.024,595.956,1500.024,599.465Z"
            transform="translate(-1389.299 -588.364)"
            fill="#fff"
          />
          <path
            d="M1530.739,606.868v-2.907a8.227,8.227,0,0,0,4.88,1.837c1.508,0,2.412-.576,2.412-1.672,0-.74-.411-1.261-1.59-1.618l-2.275-.658c-2.3-.631-3.4-1.92-3.4-3.811,0-2.467,2.056-4.14,5.374-4.14a8.517,8.517,0,0,1,4.632,1.316v2.852a7.4,7.4,0,0,0-4.413-1.453c-1.48,0-2.194.466-2.194,1.315,0,.713.493,1.207,1.508,1.48l2.248.576c2.522.631,3.564,2.057,3.564,4.03,0,2.686-2.22,4.5-5.51,4.5A8.809,8.809,0,0,1,1530.739,606.868Z"
            transform="translate(-1417.021 -588.364)"
            fill="#fff"
          />
          <path
            d="M1574.477,589.989h-3.618v10.965h-3.4V589.989H1564.2v-2.824h3.262v-3.537l3.4-.906v4.442h3.618Z"
            transform="translate(-1438.415 -581.217)"
            fill="#fff"
          />
          <path
            d="M1609.055,601.933h-9.731c.3,2.386,2,3.728,4.853,3.728a7.555,7.555,0,0,0,4.358-1.37v2.769a8.455,8.455,0,0,1-5.017,1.453c-4.632,0-7.675-2.852-7.675-7.373,0-4.223,2.6-7.238,7.018-7.238,3.811,0,6.195,2.248,6.195,6.25Zm-9.648-2.138h6.578a2.894,2.894,0,0,0-3.124-3.126A3.271,3.271,0,0,0,1599.406,599.795Z"
            transform="translate(-1458.65 -588.364)"
            fill="#fff"
          />
          <path
            d="M1649.49,594.961v3.181a3.747,3.747,0,0,0-2.193-.6c-1.755,0-2.933,1.425-2.96,3.947v6.909h-3.4V594.606h3.4v2.769c.63-2.029,1.863-3.016,3.371-3.016A2.8,2.8,0,0,1,1649.49,594.961Z"
            transform="translate(-1487.486 -588.658)"
            fill="#fff"
          />
        </g>
        <g transform="translate(9.734 26.591)">
          <path
            d="M1245.875,656.912V661.6h8.224v2.934h-8.224V673h-3.536V653.813h12.336v3.1Z"
            transform="translate(-1242.338 -653.266)"
            fill="#fff"
          />
          <path
            d="M1281.131,675c0-4.3,2.85-7.347,7.045-7.347,4.221,0,7.073,3.043,7.073,7.347,0,4.249-2.879,7.264-7.073,7.264C1284.036,682.263,1281.131,679.247,1281.131,675Zm10.663-.027c0-2.577-1.342-4.358-3.618-4.358-2.248,0-3.564,1.78-3.564,4.358,0,2.55,1.344,4.332,3.564,4.332C1290.424,679.3,1291.794,677.52,1291.794,674.971Z"
            transform="translate(-1267.144 -662.115)"
            fill="#fff"
          />
          <path
            d="M1340.713,668.794v13.789h-3.373v-2.414a4.425,4.425,0,0,1-4.249,2.8c-3.179,0-4.961-2.029-4.961-5.455v-8.717h3.4v7.922c0,2.248.905,3.4,2.769,3.4,1.948,0,3.016-1.508,3.016-4.113v-7.209Z"
            transform="translate(-1297.197 -662.846)"
            fill="#fff"
          />
          <path
            d="M1386.25,673.216v8.636h-3.4v-7.9c0-2.3-.793-3.4-2.686-3.4-2.029,0-3.152,1.48-3.152,3.892v7.4h-3.4V668.063h3.372v2.193a4.626,4.626,0,0,1,4.468-2.6C1384.413,667.651,1386.25,669.707,1386.25,673.216Z"
            transform="translate(-1326.281 -662.115)"
            fill="#fff"
          />
          <path
            d="M1430.568,652.3v19.737H1427.2v-2.55a4.907,4.907,0,0,1-4.66,2.96c-3.675,0-6.031-3.071-6.031-7.237s2.576-7.375,6.332-7.375a4.6,4.6,0,0,1,4.332,2.632V652.3Zm-3.345,12.8c0-2.522-1.372-4.3-3.592-4.3s-3.645,1.755-3.645,4.413c0,2.631,1.425,4.276,3.564,4.276C1425.8,669.484,1427.223,667.647,1427.223,665.1Z"
            transform="translate(-1353.708 -652.297)"
            fill="#fff"
          />
          <path
            d="M1475.815,673.737v8.115h-3.345V680.1a4.186,4.186,0,0,1-3.892,2.165,4.455,4.455,0,0,1-4.687-4.468c0-2.686,2.055-4.468,4.989-4.468a4.3,4.3,0,0,1,3.59,1.589v-.849a3.243,3.243,0,0,0-3.562-3.509,6.093,6.093,0,0,0-4.276,1.727v-3.1a8.586,8.586,0,0,1,4.934-1.535C1473.512,667.651,1475.815,669.954,1475.815,673.737Zm-3.317,3.838c0-1.234-1.014-2.167-2.658-2.167-1.7,0-2.66.878-2.66,2.222s.961,2.22,2.633,2.22C1471.456,679.851,1472.5,678.892,1472.5,677.576Z"
            transform="translate(-1384.009 -662.115)"
            fill="#fff"
          />
          <path
            d="M1512.577,663.74h-3.617v10.965h-3.4V663.74H1502.3v-2.824h3.262V657.38l3.4-.906v4.442h3.617Z"
            transform="translate(-1408.567 -654.968)"
            fill="#fff"
          />
          <path
            d="M1536.44,654.215a1.917,1.917,0,0,1,2.029-1.918,1.893,1.893,0,0,1,2,1.918,1.916,1.916,0,0,1-2,1.946A1.94,1.94,0,0,1,1536.44,654.215Zm.329,4.03h3.4v13.789h-3.4Z"
            transform="translate(-1430.4 -652.297)"
            fill="#fff"
          />
          <path
            d="M1555.152,675c0-4.3,2.85-7.347,7.045-7.347,4.221,0,7.073,3.043,7.073,7.347,0,4.249-2.879,7.264-7.073,7.264C1558.057,682.263,1555.152,679.247,1555.152,675Zm10.663-.027c0-2.577-1.342-4.358-3.618-4.358-2.248,0-3.564,1.78-3.564,4.358,0,2.55,1.344,4.332,3.564,4.332C1564.445,679.3,1565.815,677.52,1565.815,674.971Z"
            transform="translate(-1442.365 -662.115)"
            fill="#fff"
          />
          <path
            d="M1615.248,673.216v8.636h-3.4v-7.9c0-2.3-.794-3.4-2.686-3.4-2.029,0-3.152,1.48-3.152,3.892v7.4h-3.4V668.063h3.372v2.193a4.626,4.626,0,0,1,4.468-2.6C1613.411,667.651,1615.248,669.707,1615.248,673.216Z"
            transform="translate(-1472.712 -662.115)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LogoSquare'
};
</script>

<style lang="postcss"></style>
