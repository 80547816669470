import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getHeightHeader', 'getIsMobile']),
    headerCompensation() {
      return {
        'padding-top': `${this.getHeightHeader}px`
      };
    }
  }
};
