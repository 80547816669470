<template>
  <section class="carousel-element" :class="[persons ? 'persons' : '']">
    <div class="carousel-element__wrapper">
      <!-- <h2 v-html="title"></h2> -->
      <h3 v-if="persons" v-html="subheading"></h3>
      <div ref="carousel" class="swiper-container-element">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="slide in entries" :key="slide.id">
            <div class="swiper-slide__container">
              <router-link v-if="!persons" :to="`/news/${slide.slug}`">
                <figure>
                  <img
                    :src="slide.image.src"
                    :srcset="slide.image.srcset"
                    :alt="slide.image.title"
                  />
                  <figcaption>
                    <h6>{{ slide.date }}</h6>
                    <h5 v-if="slide.slug.length">
                      {{ slide.title }}
                    </h5>
                  </figcaption>
                </figure>
              </router-link>
              <div v-else>
                <figure>
                  <img
                    :src="slide.image.src"
                    :srcset="slide.image.srcset"
                    :alt="slide.image.title"
                  />
                  <figcaption>
                    <h5>{{ slide.title }}</h5>
                    <div v-html="slide.body"></div>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-element__navigation">
          <button
            class="arrow carousel-element__navigation__prev"
            @click="prevSlide"
          >
            <IconArrow direction="prev" />
          </button>
          <button
            class="arrow carousel-element__navigation__next"
            @click="nextSlide"
          >
            <IconArrow direction="next" />
          </button>
        </div>
      </div>

      <div class="carousel-element__cta" v-if="cta">
        <CtaItem
          :colour="cta[0].colour"
          :label="cta[0].label"
          :bubble="cta[0].bubble"
          :disabled="cta[0].disabled"
          :link="`${cta[0].link}`"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from 'swiper';

import CtaItem from '@/components/Cta/CtaItem';
import IconArrow from '@/components/Icon/IconArrow';

export default {
  name: 'CarouselElement',
  components: {
    CtaItem,
    IconArrow
  },
  props: {
    title: String,
    subheading: String,
    entries: {
      type: [Array, Object],
      required: true
    },
    persons: Boolean,
    cta: {
      type: [Array, Object]
    }
  },
  data: function() {
    return {
      carousel: null,
      options: {
        speed: 1000,
        grabCursor: false,
        centeredSlides: true,
        loop: true,
        navigation: {
          nextEl: '.carousel-element__navigation__next',
          prevEl: '.carousel-element__navigation__prev',
          disabledClass: 'disabled'
        },
        slidesPerGroup: 1,
        slidesPerView: 1.8,
        breakpoints: {
          640: {
            slidesPerView: this.persons ? 4 : 1.8
          },
          1024: {
            slidesPerView: this.persons ? 4 : 1.8
          }
        }
      }
    };
  },
  mounted() {
    this.carousel = new Swiper('.swiper-container-element', this.options);

    this.carousel.on('imagesReady', () => this.$emit('ready'));
  },
  methods: {
    prevSlide() {
      this.carousel.slidePrev();
    },
    nextSlide() {
      this.carousel.slideNext();
    }
  }
};
</script>

<style lang="postcss">
@import 'swiper/css/swiper.min.css';

.carousel-element {
  @apply flex flex-col justify-center;
  @apply bg-brand-darkerBlue;
  @apply overflow-hidden;
  .swiper-wrapper {
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    .swiper-slide {
      @apply opacity-25;
      transition-property: all;
      transition-duration: 500ms;
      transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
      transform: scale3d(0.85, 0.85, 0.85);
      &.swiper-slide-active {
        @apply opacity-100;
        transform: scale3d(1, 1, 1);
      }
    }
  }
  .swiper-container-element {
    @apply relative;
  }
  h2 {
    @apply font-ginto-black text-brand-white text-center;
    @apply mb-16;
    font-size: calc(28px + (56 - 28) * (100vw - 640px) / (1920 - 640));
  }
  h3 {
    @apply font-ginto-regular text-brand-white text-center;
    @apply mx-auto;
    @apply mb-24;
    font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
    @screen sm {
      @apply max-w-2xl;
    }
  }
  figure {
    img {
      @apply mx-auto;
      @screen sm {
        width: 500px;
      }
      @screen xxl {
        width: 650px;
      }
    }
    figcaption {
      @apply mt-8;
      @apply text-brand-white text-center;
      font-size: calc(14px + (14 - 14) * (100vw - 640px) / (1920 - 640));
      @apply max-w-screen-sm mx-auto;
      h6 {
        @apply text-brand-light-blue;
        font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
        @screen sm {
          @apply mt-24;
        }
      }
      h5 {
        font-size: calc(16px + (26 - 16) * (100vw - 640px) / (1920 - 640));
        @apply cursor-pointer;
      }
    }
  }
  &__cta {
    @apply flex justify-center;
    @apply mt-16;
  }
  &__navigation {
    @apply hidden;
    @screen sm {
      @apply flex items-center;
      @apply absolute top-0;
      @apply h-full w-full;
      transform: translateY(-8vh);
      @apply pointer-events-none;
      z-index: 99999999;
    }
    /* previous arrow */
    .carousel-element__navigation__prev {
      z-index: 99999999;
      @apply pointer-events-auto;
      @apply absolute left-0;
      @apply cursor-pointer;
      @apply outline-none appearance-none;
      @apply p-4 py-12;
      @screen sm {
        transform: translateX(25vw);
      }
      @screen lg {
        transform: translateX(20vw);
      }
    }
    /* next arrow */
    .carousel-element__navigation__next {
      z-index: 99999999;
      @apply pointer-events-auto;
      @apply absolute right-0;
      @apply cursor-pointer;
      @apply outline-none appearance-none;
      @apply p-4 py-12;
      @screen sm {
        transform: translateX(-25vw);
      }
      @screen lg {
        transform: translateX(-20vw);
      }
    }
    .arrow {
      transition-duration: 500ms;
      transition-property: opacity;
      transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
      @apply opacity-100;
      z-index: 999999;
      &.disabled {
        @apply opacity-25;
      }
    }
  }
  &.persons {
    figure {
      img {
        @apply rounded-full;
        width: 180px;
        height: 180px;
        @apply object-cover;
        @screen xxl {
          width: 300px;
          height: 300px;
        }
      }
      figcaption {
        @apply mt-16;
        @apply max-w-md;
        h5 {
          font-size: calc(16px + (26 - 16) * (100vw - 640px) / (1920 - 640));
          @apply mb-4;
        }
        p {
          font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
        }
      }
    }
    .carousel-element__navigation {
      transform: translateY(-8vh);
    }
    /* previous arrow */
    .carousel-element__navigation__prev {
      @screen sm {
        transform: translateX(25vw);
      }
      @screen lg {
        transform: translateX(36vw);
      }
    }
    /* next arrow */
    .carousel-element__navigation__next {
      @screen sm {
        transform: translateX(-25vw);
      }
      @screen lg {
        transform: translateX(-36vw);
      }
    }
  }
}
</style>
