<template>
  <div class="utility-mobile-landscape">
    <div>
      <LogoSquare />
      <h2>
        Please rotate your device<br />
        for a better experience
      </h2>
    </div>
  </div>
</template>

<script>
import LogoSquare from '@/components/Logo/LogoSquare';
export default {
  name: 'UtilityMobileLandscape',
  components: {
    LogoSquare
  }
};
</script>

<style lang="postcss" scoped>
.utility-mobile-landscape {
  @apply hidden;
  @screen sm {
    @define-mixin messageProps {
      @apply block;
      @apply bg-brand-darkerBlue;
      @apply fixed top-0 left-0;
      @apply h-screen w-screen;
      z-index: 999999;
      @apply flex items-center justify-center flex-wrap;
      @apply text-center;
      svg {
        @apply mx-auto;
      }
      h2 {
        @apply py-12;
        @apply w-full;
        @apply text-center text-white text-mobile-medium font-ginto-regular;
        @apply leading-tight;
      }
    }

    @each $min, $max, ratio in (320, 375, 375), (568, 667, 812), (2, 2, 3) {
      @media only screen and (min-device-width: $(min)px) and (max-device-width: $(max)px) and (-webkit-min-device-pixel-ratio: $ratio) and (orientation: landscape) {
        @mixin messageProps;
      }
    }
  }
}
</style>
