<template>
  <div class="modal-window" v-if="getIsModalWindowOpen">
    <div
      class="modal-window__container"
      :style="getIsMobile ? modalWindowPosition : ''"
    >
      <div class="modal-window__header">
        <IconInfo />
        <button
          class="modal-window__header__close"
          @click="toggleModalWindowOpen"
        >
          Close Panel
          <IconCloseBlue />
        </button>
      </div>
      <div class="modal-window__content">
        <h2>
          Westminster Foundation contributes to the Grosvenor Estate’s
          Coronavirus response
        </h2>
        <h3>
          Grosvenor Estate helps local communities cope with Coronavirus
        </h3>
        <p>
          The Grosvenor Estate is helping businesses, families and the most
          vulnerable in society manage the impact of Coronavirus through
          different forms of assistance within the communities we are part of.
        </p>
        <p>
          With the full support and encouragement of the Duke of Westminster,
          the Grosvenor family and the Grosvenor Estate Trustees, all parts of
          our organisation, including the Westminster Foundation, international
          property company Grosvenor Group, food and agriculture investor
          Wheatsheaf Group and the Grosvenor Estate Family Office, which manages
          a number of rural estates, are working – in the UK and internationally
          – with urban and rural communities to offer practical support, and in
          many cases a lifeline, to help businesses and individuals overcome the
          present situation.
        </p>
        <p>
          Our initiatives include the Westminster Foundation making an initial
          sum of £2.5m immediately available to support vulnerable groups and
          key workers. The money will predominantly be used to support
          vulnerable families through the provision of food and activities to
          promote the mental and physical wellbeing of young people.
        </p>
        <p>
          Please visit
          <a href="https://www.grosvenorestate.com" target="_blank"
            >www.grosvenorestate.com</a
          >
          for a full list of initiatives the Grosvenor Estate is taking to help
          local communities cope with Coronavirus.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import IconInfo from '@/components/Icon/IconInfo';
import IconCloseBlue from '@/components/Icon/IconCloseBlue';

export default {
  name: 'ModalWindow',
  components: {
    IconInfo,
    IconCloseBlue
  },
  props: {},
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getIsModalWindowOpen',
      'getHeightMobileMenu'
    ]),
    modalWindowPosition() {
      return {
        transform: `translateY(${this.heightMobileMenu}px)`,
        height: `${window.innerHeight - this.heightMobileMenu}px`
      };
    }
  },
  methods: {
    toggleModalWindowOpen() {
      this.$store.commit('setIsModalWindowOpen', !this.getIsModalWindowOpen);
    }
  },
  data: () => {
    return {};
  }
};
</script>

<style lang="postcss" scoped>
.modal-window {
  z-index: 999;
  @apply fixed top-0 left-0;
  @screen sm {
    @apply flex justify-center items-center;
    @apply w-screen h-screen;
  }
  &__container {
    @apply bg-brand-white;
    @screen sm {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      @apply max-w-screen-md;
      height: 300px;
      @apply overflow-hidden;
    }
    @screen xl {
      height: 400px;
    }
    @screen screen1440 {
      height: 500px;
    }
    @screen xxl {
      height: 650px;
      @apply max-w-screen-lg;
    }
  }
  &__header {
    @apply flex justify-between;
    @apply p-10;
    &__close {
      @apply flex items-center justify-between;
      @apply cursor-pointer;
      @apply font-ginto-regular text-brand-blue;
      svg {
        @apply ml-4;
      }
    }
  }
  &__content {
    @apply h-full;
    @apply overflow-y-scroll;
    @apply text-brand-blue;
    @apply px-10 pb-40;
    h2 {
      @apply font-ginto-regular leading-snug;
      font-size: calc(12px + (26 - 12) * (100vw - 640px) / (1920 - 640));
      @apply mb-8;
    }
    h3 {
      @apply font-ginto-regular;
      font-size: calc(12px + (14 - 12) * (100vw - 640px) / (1920 - 640));
      @apply mb-8;
    }
    p {
      @apply font-ginto-regular;
      font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
      @apply pb-8;
    }
  }
}
</style>
