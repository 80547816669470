<template>
  <section
    class="block-plain"
    :class="[background]"
    :style="headerCompensation"
  >
    <div class="block-plain__container">
      <h1 v-html="block.heading"></h1>
      <div v-html="block.body"></div>
      <CoreLinks v-if="block.options.links" class="block-plain__links" />
    </div>
  </section>
</template>

<script>
import { background, headerCompensation } from '@/mixins';
import CoreLinks from '@/components/Core/CoreLinks';

export default {
  name: 'BlockPlain',
  components: { CoreLinks },
  props: {
    block: [Array, Object]
  },
  mixins: [background, headerCompensation]
};
</script>

<style lang="postcss">
.block-plain {
  @apply pb-24;
  @apply text-center;
  &__container {
    @apply max-w-screen-sm mx-auto;
  }
  h1 {
    @apply font-ginto-black text-center;
    font-size: calc(38px + (80 - 38) * (100vw - 640px) / (1920 - 640));
    @apply py-8;
    @screen sm {
      @apply py-16;
    }
  }
  &__links {
    @apply py-8;
    @apply w-full;
    @apply flex justify-center;
    @apply gap-8;
    @apply text-brand-darkerBlue;
  }
  font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
  @apply font-ginto-regular;
  h3 {
    @apply font-ginto-black;
    font-size: calc(16px + (26 - 16) * (100vw - 640px) / (1920 - 640));
    @apply mb-8;
  }
  p {
    @apply mb-8;
    a {
      @apply border-b border-brand-blue;
      @apply pb-1;
    }
    &:only-of-type {
      @apply text-center;
    }
  }
  em {
    @apply font-ginto-regular-italic;
  }
  ul {
    @apply list-disc;
    li {
      @apply mb-4;
      ul {
        @apply ml-16;
        @apply mt-4;
      }
    }
  }
  table {
    @apply my-16;
  }
  table,
  th,
  td {
    @apply border border-brand-dark-grey;
    border-collapse: collapse;
  }
  td {
    vertical-align: top;
    @apply p-4;
  }
}
</style>
