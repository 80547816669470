const bindingHandler = (el, event, handler, action) => {
  const events = Array.isArray(event) ? event : String(event).split(' ');
  events.forEach(e => {
    el[`${action ? 'add' : 'remove'}EventListener`](e, handler, false);
  });
};

/**
 * Bind an event listener
 * @param {element} el
 * @param {string} event
 * @param {function} handler
 */
export const bind = (el, event, handler) =>
  bindingHandler(el, event, handler, true);

/**
 * Unbind an event listener
 * @param {element} el
 * @param {string} event
 * @param {function} handler
 */
export const unbind = (el, event, handler) =>
  bindingHandler(el, event, handler, false);
