<template>
  <div class="salad bg-brand-green">
    <h3 class="salad__heading">{{ block.title }}</h3>
    <div class="salad__grid">
      <a
        v-for="(logo, i) in block.imageLinks"
        :key="i"
        :href="logo.itemUrl"
        :title="logo.itemName"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img :src="logo.itemImage.raw" :alt="logo.itemName" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockLogos',
  props: {
    block: [Array, Object]
  }
};
</script>

<style lang="postcss" scoped>
.salad {
  @apply flex flex-col items-center justify-center;
  gap: 84px;
  @apply p-24;
  padding-top: 50px;
  padding-bottom: 50px;
  @screen lg {
    padding-top: 84px;
    padding-bottom: 84px;
  }
}
.salad__heading {
  @apply text-white font-ginto-black text-center;
  font-size: calc(28px + (56 - 28) * (100vw - 640px) / (1920 - 640));
}
.salad__grid {
  display: grid;
  gap: 36px;
  max-width: 1600px;
  grid-template-columns: repeat(2, 1fr);
  @screen sm {
    grid-template-columns: repeat(3, 1fr);
  }
  @screen md {
    grid-template-columns: repeat(4, 1fr);
  }
  @screen lg {
    grid-template-columns: repeat(6, 1fr);
  }
  @screen xl {
    grid-template-columns: repeat(8, 1fr);
  }
}
</style>
