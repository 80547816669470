import Vue from 'vue';
import App from './App.vue';
import VueGtm from '@gtm-support/vue2-gtm';
import router from './router';
import store from './store';

// Mixins
import titleMixin from './mixins/titleMixin';
Vue.mixin(titleMixin);

/**
 * Importing Talwind Configuration which will be used globally
 */
const tailwindConfig = require('../tailwind.config.js');
Vue.prototype.$colours = Object.freeze(
  tailwindConfig.theme.extend.colors.brand
);

// Google Analytics with vue-gtag
Vue.use(VueGtm, {
  id: 'GTM-5GVBLB64', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

// Vue.use(
//   VueGtag,
//   {
//     config: {
//       id: 'G-435428692',
//       params: {
//         send_page_view: true
//       }
//     }
//   },
//   router
// );

/**
 * Setting $http as a global instance
 */
import axios from 'axios';
Vue.prototype.$http = axios;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

/**
 * The holding page was mistakenly built as a PWA that installed a service
 * worker that cached the holding page for offline use. This was never removed
 * causing the holding page to still be the page loaded by browsers rather than
 * the correct site
 */
navigator.serviceWorker.getRegistrations().then(registrations => {
  for (let registration of registrations) {
    registration.unregister();
  }
});
