import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';
import Page from '../views/Page.vue';
import CaseStudy from '../views/CaseStudy.vue';
import NewsCover from '../views/NewsCover.vue';
import NewsEntry from '../views/NewsEntry.vue';
import Reports from '../views/Reports.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { scrollToTop: true }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  // {
  //   path: '/who-we-fund',
  //   name: 'Who we fund',
  //   component: () => import('../views/WhoWeFund.vue')
  // },
  {
    path: '/news',
    name: 'NewsCover',
    component: NewsCover,
    meta: { scrollToTop: true }
  },
  {
    path: '/news/:slug',
    name: 'NewsEntry',
    component: NewsEntry,
    props: route => ({
      slug: route.params.slug
    }),
    meta: { scrollToTop: true }
  },
  {
    path: '/reports-and-resources',
    name: 'Reports & Resources',
    component: Reports,
    meta: { scrollToTop: true }
  },
  {
    path: '/:slug',
    name: 'Page',
    component: Page,
    props: route => ({
      slug: route.params.slug
    }),
    meta: { scrollToTop: true }
  },
  {
    path: '/case-study/:slug',
    name: 'CaseStudy',
    component: CaseStudy,
    props: route => ({
      slug: route.params.slug
    }),
    meta: { scrollToTop: true }
  }
];

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = function(to, from, savedPosition) {
  // console.log(savedPosition);
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition;
  } else {
    const position = {};

    // scroll to anchor by returning the selector
    if (to.hash) {
      // console.log(to.hash);
      position.selector = to.hash;

      // bypass #1number check
      if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
        // console.log('bypass');
        return position;
      }

      // if the returned position is falsy or an empty object,
      // will retain current scroll position.
      return false;
    }

    return new Promise(resolve => {
      // console.log(to.meta.scrollToTop);
      // check if any matched route config has meta that requires scrolling to top
      if (to.matched.some(m => m.meta.scrollToTop)) {
        // coords will be used if no selector is provided,
        // or if the selector didn't match any element.
        position.x = 0;
        position.y = 0;
      }

      // console.log(position);
      resolve(position);
      // wait for the out transition to complete (if necessary)
      // this.app.$root.$once('triggerScroll', () => {
      //   // if the resolved position is falsy or an empty object,
      //   // will retain current scroll position.
      //   console.log(position);
      //   resolve(position);
      // });
    });
  }
};

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior
});

export default router;
