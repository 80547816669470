<template>
  <div class="logo-emblem">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.118"
      height="33.118"
      viewBox="0 0 33.118 33.118"
    >
      <path
        d="M447.915,451.724a12.277,12.277,0,0,0,1.029-.818.16.16,0,0,0,.007-.231q-.362-.376-.683-.772a.155.155,0,0,0-.226-.018,10.622,10.622,0,0,1-.895.714c-2.653,1.737-4.419,3.453-4.419,6.186,0,2.188,1.344,3.185,2.593,3.185a2.924,2.924,0,0,0,2.773-3.182l0-.353a14.086,14.086,0,0,0-.439-3.484.166.166,0,0,0-.256-.09q-.47.339-.839.648a.311.311,0,0,0-.1.306,12.733,12.733,0,0,1,.272,2.617l0,.353c0,1.251-.731,1.822-1.41,1.822-.747,0-1.229-.715-1.229-1.822C444.091,454.9,445.086,453.576,447.915,451.724Z"
        transform="translate(-432.969 -433.302)"
      />
      <path
        d="M420.331,409.357l-.979.121a.159.159,0,0,0-.129.211,15.206,15.206,0,0,1-12.021,20.1,15.216,15.216,0,0,1-17.5-15.026,15.059,15.059,0,0,1,1.334-6.246,13.193,13.193,0,0,1,9.752,7.182.153.153,0,0,0,.229.056c.246-.182.5-.359.753-.532a.31.31,0,0,0,.1-.4,14.565,14.565,0,0,0-11.157-7.731l-.154-.019a.464.464,0,0,0-.473.256l-.068.139a16.4,16.4,0,0,0-1.683,7.292,16.559,16.559,0,1,0,33.105-.655,16.4,16.4,0,0,0-.834-4.58A.262.262,0,0,0,420.331,409.357Z"
        transform="translate(-388.34 -398.209)"
      />
      <path
        d="M469.6,412.289q.161-.456.351-.9a.3.3,0,0,0-.09-.355c-2.029-1.622-3.315-3.836-3.315-5.778,0-1.251.731-1.822,1.41-1.822s1.408.57,1.408,1.822a6.187,6.187,0,0,1-.923,3.058.306.306,0,0,0,.011.333,9.084,9.084,0,0,0,.653.8.165.165,0,0,0,.256-.02,7.746,7.746,0,0,0,1.367-4.174,2.8,2.8,0,1,0-5.544,0c0,2.488,1.585,5.184,4.172,7.109A.16.16,0,0,0,469.6,412.289Z"
        transform="translate(-451.392 -394.101)"
      />
      <path
        d="M403.767,365.726a.246.246,0,0,0,.254-.106,15.206,15.206,0,0,1,25.754,1.073A14.434,14.434,0,0,0,418,380.785l0,.347a2.925,2.925,0,0,0,2.773,3.185c1.249,0,2.593-1,2.593-3.185a5.956,5.956,0,0,0-2.347-4.645.157.157,0,0,0-.249.061q-.188.506-.33,1.031a.317.317,0,0,0,.087.306A4.274,4.274,0,0,1,422,381.132c0,1.107-.483,1.822-1.229,1.822-.679,0-1.41-.57-1.41-1.825l0-.346a13.083,13.083,0,0,1,11.547-12.888l.6-.074a.253.253,0,0,0,.2-.362l-.114-.232a16.892,16.892,0,0,0-15.235-9.574,16.439,16.439,0,0,0-13.776,7.7.126.126,0,0,0,.086.193C402.939,365.583,403.457,365.663,403.767,365.726Z"
        transform="translate(-400.004 -357.65)"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LogoEmblem'
};
</script>
