<template>
  <main ref="loader" class="news-cover" :style="headerCompensation">
    <h1>News & Insights</h1>
    <div class="news-cover__categories" v-if="false">
      <span>Categories:</span>
      <ul v-if="content">
        <li
          @click="applyFilter()"
          :class="[category === '' || category == null ? 'active' : '']"
        >
          All
        </li>
        <li
          v-for="(category, index) in categories"
          :key="`category-${index}`"
          @click="applyFilter(category.slug)"
          :class="[isActive(category.slug)]"
        >
          {{ category.title }}
        </li>
      </ul>
    </div>
    <div class="news-cover__entries">
      <CardNews
        class="item"
        v-for="entry in filteredList"
        :key="`news-entry-${entry.id}`"
        :entry="entry"
      />
    </div>
  </main>
</template>

<script>
import { loader, headerCompensation } from '@/mixins';
import CardNews from '@/components/Card/CardNews';

export default {
  name: 'NewsCover',
  components: {
    CardNews
  },
  // used with Title Mixin
  title: 'News',
  data: () => {
    return {
      content: null,
      category: null
    };
  },
  mixins: [loader, headerCompensation],
  computed: {
    categories() {
      const list = this.content.map(item => item.category);
      return list.filter((item, index) => list.indexOf(item) === index);
    },
    filteredList() {
      return this.category
        ? this.content.filter(item => item.category.slug === this.category)
        : this.content;
    }
  },
  methods: {
    getContent() {
      this.$http
        .get(`api/v1/news.json`)
        .then(response => {
          this.content = response.data.data;
        })
        .catch(error => console.error(error));
    },
    applyFilter(categorySlug) {
      this.category = categorySlug;
    },
    isActive(categorySlug) {
      return categorySlug === this.category ? 'active' : '';
    }
  }
};
</script>

<style lang="postcss">
.news-cover {
  @apply bg-brand-cream;
  h1 {
    @apply font-ginto-black text-center;
    @apply py-16;
    font-size: calc(38px + (80 - 38) * (100vw - 640px) / (1920 - 640));
  }
  &__categories {
    @apply flex flex-col;
    @apply py-16;
    @apply text-center;
    @screen sm {
      @apply text-left;
      @apply flex-row justify-center;
      @apply py-32;
    }
    span {
      @apply mb-8;
      font-size: calc(16px + (26 - 16) * (100vw - 640px) / (1920 - 640));
      @screen sm {
        @apply mb-0;
        @apply mr-8;
      }
    }
    ul {
      @apply list-none;
      li {
        @apply inline-block;
        @apply mr-12;
        @apply border-b border-brand-super-light-blue;
        @apply pb-2;
        @apply cursor-pointer;
        font-size: calc(16px + (26 - 16) * (100vw - 640px) / (1920 - 640));
        &:last-of-type {
          @apply mr-0;
        }
        &.active {
          @apply border-brand-blue;
        }
      }
    }
  }
  &__entries {
    @apply flex flex-wrap;
    @screen sm {
      @apply max-w-max mx-auto;
      @apply justify-between;
    }
  }
}
</style>
