<template>
  <section class="block-person" :class="[background]">
    <div class="block-person__container">
      <h2 v-html="block.heading"></h2>
      <h3 v-html="block.subHeading"></h3>
      <div class="block-person__media">
        <img
          :src="block.media.images[0].src"
          :srcset="block.media.images[0].srcset"
          :alt="block.media.images[0].title"
        />
      </div>
      <div class="block-person__content">
        <div
          class="block-person__summary"
          v-html="block.summary"
          v-if="!openFull"
        ></div>
        <ButtonReadMore
          colour="#fff"
          afterlabel="Read Less"
          @click.native="openFull = !openFull"
          v-if="!openFull"
        />
        <div
          class="block-person__full"
          v-html="block.body"
          v-if="openFull"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import { background } from '@/mixins';
import ButtonReadMore from '@/components/Button/ButtonReadMore';

export default {
  name: 'BlockPerson',
  components: {
    ButtonReadMore
  },
  props: {
    block: [Array, Object]
  },
  data: () => {
    return {
      openFull: false
    };
  },
  mixins: [background]
};
</script>

<style lang="postcss">
.block-person {
  @apply flex flex-col items-center;
  @apply py-16;
  @screen sm {
    @apply min-h-screen;
    @apply justify-center;
    @apply py-32;
  }
  h2 {
    @apply font-ginto-black text-center text-brand-white leading-tight;
    font-size: calc(28px + (56 - 28) * (100vw - 640px) / (1920 - 640));
  }
  h3 {
    @apply font-ginto-regular text-white text-center leading-tight;
    font-size: calc(28px + (56 - 28) * (100vw - 640px) / (1920 - 640));
  }
  &__media {
    @apply py-16;
    img {
      width: 180px;
      height: 180px;
      @apply rounded-full;
      @apply object-cover;
      @apply mx-auto;
      @screen sm {
        width: 400px;
        height: 400px;
      }
    }
  }
  &__content {
    @apply text-brand-white font-ginto-regular;
    @apply max-w-max mx-auto;
    font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
    @screen sm {
      @apply max-w-screen-md mx-0;
    }
    p {
      @apply mb-8;
      &:last-of-type {
        @apply mb-0;
      }
    }
  }
}
</style>
