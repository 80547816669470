import { gsap } from 'gsap';
export default {
  props: {
    slug: String
  },
  data: () => {
    return {
      intro: null,
      outro: null
    };
  },
  watch: {
    content(val) {
      if (val != null) {
        this.$store.commit('setIsContentLoaded', true);
        this.intro.restart();
        document.body.style.overflow = 'visible';
      }
    },
    slug: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          this.getContent(val);
          this.initLoader();
        });
      }
    }
  },
  methods: {
    initLoader() {
      // timelines
      this.intro = gsap.timeline({ paused: true });
      this.outro = gsap.timeline({ paused: true });

      // movement
      this.intro.fromTo(
        this.$refs.loader,
        1,
        { autoAlpha: 0 },
        { autoAlpha: 1, ease: 'power3.inOut' }
      );
      this.outro.to(this.$refs.loader, 0.5, {
        autoAlpha: 0,
        ease: 'power3.out'
      });
    },
    playIntro() {
      this.intro.play();
    },
    playOutro(next) {
      this.outro.eventCallback('onComplete', next);
      this.outro.restart();
      // this.scrollToTop();
    },
    scrollToTop() {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    },
    resetStore() {
      this.$store.commit('setIsContentLoaded', false);
      this.$store.commit('setFilterLocation', undefined);
      this.$store.commit('setFilterCaseStudy', undefined);
      this.$store.commit('setIsFilterListLoaded', false);
      this.$store.commit('setIsMenuOpen', false);
    }
  },
  beforeRouteUpdate(to, from, next) {
    document.body.style.overflow = 'hidden';
    this.resetStore();
    this.playOutro(next);
  },
  beforeRouteLeave(to, from, next) {
    document.body.style.overflow = 'hidden';
    this.resetStore();
    this.playOutro(next);
  }
};
