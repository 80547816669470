<template>
  <section
    class="block-text"
    :class="[background, image ? 'with-image' : '', { grants: isGrantsPage }]"
  >
    <div class="block-text__container">
      <div class="block-text__content">
        <div
          v-if="block.heading"
          class="block-text__content__heading"
          :class="{ increase: block.options.increaseHeadingSize }"
        >
          <h3 :class="{ grants: isGrantsPage }">{{ block.heading }}</h3>
        </div>
        <div
          class="block-text__content__item"
          :class="item.offset ? 'justify-end' : ''"
          v-for="(item, index) in block.textGroup"
          :key="`text-group-${index}`"
        >
          <div
            :class="[
              item.position ? item.position : 'left',
              item.offset ? 'offset' : ''
            ]"
            v-html="item.content"
          ></div>
        </div>
      </div>
      <div
        class="block-text__content__cta"
        v-for="(item, index) in block.ctaGroup"
        :key="`cta-${index}`"
      >
        <CtaItem
          :label="item.label"
          :link="`${item.link}`"
          :colour="item.colour"
          :position="item.position"
        />
      </div>
    </div>
    <div class="block-text__media" v-if="image">
      <img :src="image.src" :srcset="image.srcset" :alt="image.alt" />
    </div>
  </section>
</template>

<script>
import { background } from '@/mixins';
import CtaItem from '@/components/Cta/CtaItem';

export default {
  name: 'BlockText',
  components: {
    CtaItem
  },
  props: {
    block: [Array, Object]
  },
  mixins: [background],
  computed: {
    image() {
      return this.block.media.images.length ? this.block.media.images[0] : null;
    },
    isGrantsPage() {
      return window.location.pathname.split('/')[1] === 'grants' ? true : false;
    }
  }
};
</script>

<style lang="postcss">
.block-text {
  &.grants {
    .block-text__content__heading {
      h3 {
        font-size: calc(16px + (32 - 16) * (100vw - 640px) / (1920 - 640));
      }
    }
    h4 {
      font-size: calc(16px + (26 - 16) * (100vw - 640px) / (1920 - 640));
      @apply leading-snug;
    }
  }
  @apply flex flex-wrap;
  &.bg-brand-green,
  &.bg-brand-darkerBlue {
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      @apply text-white;
    }
  }
  &.bg-brand-grey {
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      @apply text-brand-darkerBlue;
    }
  }
  &__container {
    @apply flex flex-col justify-center items-center;
    @apply mx-auto;
    @apply py-16;
    @apply z-40;
    @screen sm {
      @apply py-32;
    }
  }
  &__content {
    @apply w-9/10;
    h2 {
      @apply font-ginto-black;
      @apply mb-40;
      font-size: calc(20px + (40 - 20) * (100vw - 640px) / (1920 - 640));
      @apply leading-snug;
      &:last-of-type {
        @apply mb-0;
      }
    }
    h3 {
      @apply font-ginto-black;
      @apply mb-16;
      font-size: calc(20px + (36 - 20) * (100vw - 640px) / (1920 - 640));
      @apply leading-snug;
    }
    h4 {
      @apply font-ginto-black;
      font-size: calc(20px + (36 - 20) * (100vw - 640px) / (1920 - 640));
      @apply leading-snug;
      @apply pb-8;
      @screen sm {
        @apply pb-16;
      }
      &:last-of-type {
        @screen sm {
          @apply pb-0;
        }
      }
    }
    h5 {
      @apply pb-16;
      font-size: calc(14px + (20 - 14) * (100vw - 640px) / (1920 - 640));
      &:last-of-type {
        @apply pb-0;
      }
    }
    h6 {
      font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
      @apply max-w-3xl;
      @apply mx-auto;
    }
    p {
      @apply font-ginto-regular;
      @apply pb-8;
      @apply leading-snug;
      font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
      strong {
        @apply font-ginto-bold;
      }
      &:last-of-type {
        @apply pb-0;
      }
      @screen sm {
        @apply pb-8;
      }
    }
    &__heading {
      h3 {
        @apply text-center;
        font-size: calc(16px + (26 - 16) * (100vw - 640px) / (1920 - 640));
      }
      &.increase {
        h3 {
          font-size: calc(28px + (56 - 28) * (100vw - 640px) / (1920 - 640));
        }
      }
    }
    &__item {
      @apply flex;
      @apply pb-16;
      @screen sm {
        @apply pb-32;
      }
      &:last-of-type {
        @apply pb-0;
      }
      .left {
        @apply text-left;
      }
      .center {
        @apply text-center;
      }
      .right {
        @apply text-right;
      }
      .offset {
        @apply max-w-md;
        @screen sm {
          @apply max-w-screen-sm;
        }
      }
    }
    &__cta {
      @apply pt-8;
      @screen sm {
        @apply pt-16;
      }
    }
    @screen sm {
      @apply max-w-screen-lg;
    }
  }
  &__media {
    @screen sm {
      @apply h-screen;
      @apply w-1/2;
    }
    img {
      @apply object-cover;
      @apply h-full;
    }
  }
  &.with-image {
    @apply min-h-screen;
    @screen sm {
      @apply h-auto;
    }
    .block-text__container {
      @screen sm {
        @apply w-1/2;
        @apply max-w-screen-md;
      }
    }
  }
  &.remove-spacing {
    .block-text__container {
      @apply pt-0;
    }
  }
}
</style>
