<template>
  <div class="icon-arrow-menu">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.027"
      height="5.804"
      viewBox="0 0 15.027 5.804"
      v-if="direction === 'next'"
    >
      <path
        d="M303.99,5162.311l-4.376-2.9v2.4H288.962v1h10.651v2.4Z"
        transform="translate(-288.962 -5159.408)"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.027"
      height="5.804"
      viewBox="0 0 15.027 5.804"
      v-else-if="direction === 'back'"
    >
      <path
        d="M303.99,5162.311l-4.376-2.9v2.4H288.962v1h10.651v2.4Z"
        transform="translate(303.99 5165.212) rotate(180)"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconArrowMenu',
  props: {
    direction: {
      type: String,
      defualt: 'next'
    }
  }
};
</script>

<style lang="postcss" scoped>
.icon-arrow-menu {
  &.back {
    transform: rotate(-180deg);
  }
}
</style>
