<template>
  <section class="block-steps" :class="[background]">
    <svg
      viewBox="0 0 1440 365"
      xmlns="http://www.w3.org/2000/svg"
      class="arrows"
    >
      <g fill="#5e6eff">
        <path
          d="m63.5 123.5c-2.69 0-5.37-1.02-7.42-3.08-4.1-4.1-4.1-10.75 0-14.85l43.58-43.58-43.58-43.57c-4.1-4.1-4.1-10.75 0-14.85s10.75-4.1 14.85 0l49.59 49.59c2.36 2.36 3.66 5.5 3.66 8.84s-1.3 6.48-3.66 8.84l-49.59 49.59c-2.05 2.05-4.74 3.08-7.42 3.08zm42.16-55.49h.01z"
        />
        <path
          d="m400.99 179.34c-4.64 0-8.88-3.09-10.14-7.78-1.5-5.6 1.82-11.36 7.42-12.86l59.53-15.95-15.95-59.52c-1.5-5.6 1.82-11.36 7.42-12.86 5.6-1.51 11.36 1.82 12.86 7.42l18.15 67.74c1.78 6.65-2.18 13.52-8.84 15.31l-67.74 18.15c-.91.24-1.83.36-2.72.36z"
        />
        <path
          d="m849.39 307.73c-1.78 0-3.59-.45-5.24-1.41-5.02-2.9-6.74-9.32-3.84-14.34l30.81-53.37-53.37-30.81c-5.02-2.9-6.74-9.32-3.84-14.34s9.32-6.74 14.34-3.84l60.73 35.06c2.89 1.67 4.96 4.37 5.82 7.59.86 3.23.42 6.59-1.25 9.48l-35.06 60.73c-1.95 3.37-5.47 5.25-9.1 5.25z"
        />
        <path
          d="m1133.01 262.11c-3.38 0-6.69-1.63-8.72-4.64-3.24-4.81-1.96-11.34 2.85-14.57l51.12-34.41-34.41-51.12c-3.24-4.81-1.96-11.34 2.85-14.57 4.81-3.24 11.33-1.96 14.57 2.85l39.16 58.18c3.85 5.68 2.34 13.47-3.36 17.33l-58.2 39.17c-1.8 1.21-3.84 1.79-5.85 1.79zm49.99-46.58s.02.03.02.04z"
        />
        <path
          d="m428.99 139.18c-1.81 0-3.64-.47-5.31-1.45-119.06-69.97-231.01-71.98-273.57-63.27-5.68 1.16-11.23-2.5-12.39-8.18s2.5-11.23 8.18-12.39c45.29-9.27 163.86-7.47 288.43 65.73 5 2.94 6.67 9.37 3.73 14.37-1.96 3.33-5.46 5.18-9.06 5.18z"
        />
        <path
          d="m721.32 276.73c-16.64 0-31.9-1.55-46.07-4.63-39.63-8.61-67.19-27.67-99.1-49.74-21.48-14.85-43.68-30.21-72.84-44.79-5.19-2.59-7.29-8.9-4.7-14.09s8.9-7.29 14.09-4.7c30.49 15.25 54.35 31.75 75.4 46.3 30.08 20.8 56.05 38.77 91.61 46.49 39.18 8.51 88.45 4.05 155.04-14.04 5.6-1.52 11.37 1.78 12.88 7.38 1.52 5.6-1.78 11.36-7.38 12.88-46.69 12.68-85.57 18.92-118.93 18.92z"
        />
        <path
          d="m-77.49 103.68c-3.25 0-6.46-1.51-8.51-4.34-3.4-4.7-2.35-11.26 2.35-14.66 8.07-5.84 22.82-14.28 47.25-21.28 28.31-8.11 62.42-12.22 101.4-12.22 5.8 0 10.5 4.7 10.5 10.5s-4.7 10.5-10.5 10.5c-86.28 0-124.09 20.63-136.34 29.5-1.86 1.35-4.02 2-6.15 2z"
        />
        <path
          d="m917.5 238.68c-4.39 0-8.48-2.77-9.95-7.16-1.85-5.5 1.11-11.45 6.61-13.3 12.16-4.08 24.06-9.27 36.67-14.75 49.43-21.51 105.46-45.9 200.59-13.22 5.48 1.88 8.4 7.86 6.52 13.34s-7.86 8.4-13.34 6.52c-87.42-30.03-137.22-8.35-185.38 12.62-13.03 5.67-25.34 11.03-38.36 15.41-1.11.37-2.24.55-3.35.55z"
        />
        <path
          d="m1509.5 364.68c-2.82 0-5.63-1.13-7.7-3.36-23.82-25.65-69.03-53.42-130.76-80.31-65.6-28.57-124.76-45.66-141.33-50.21-5.59-1.54-8.88-7.32-7.34-12.91s7.32-8.88 12.91-7.34c16.91 4.65 77.28 22.08 144.15 51.21 65.28 28.44 111.63 57.12 137.76 85.27 3.95 4.25 3.7 10.89-.55 14.84-2.02 1.88-4.58 2.81-7.14 2.81z"
        />
      </g>
    </svg>
    <ol class="block-steps__container">
      <li
        v-for="(item, index) in block.details"
        :key="`step-${index}`"
        class="step"
      >
        <svg
          v-if="index == 0"
          viewBox="0 0 375 164"
          xmlns="http://www.w3.org/2000/svg"
          class="arrow"
        >
          <g fill="#5e6eff">
            <path
              d="m414.48 115.29c-1.29 0-2.6-.33-3.79-1.04-101.33-59.55-196.7-61.24-232.97-53.81-4.05.83-8.02-1.78-8.85-5.84s1.79-8.02 5.84-8.85c38.22-7.83 138.32-6.28 243.58 55.57 3.57 2.1 4.76 6.69 2.67 10.27-1.4 2.38-3.9 3.7-6.47 3.7z"
            />
            <path
              d="m104.56 101.99c-1.92 0-3.84-.73-5.3-2.2-2.93-2.93-2.93-7.68 0-10.61l37.94-37.94-37.94-37.94c-2.93-2.93-2.93-7.68 0-10.61 2.93-2.93 7.68-2.93 10.61 0l41.83 41.83c3.7 3.7 3.7 9.73 0 13.43l-41.83 41.83c-1.46 1.46-3.38 2.2-5.3 2.2zm36.53-46.86h.01z"
            />
            <path
              d="m-14.99 85.5c-2.31 0-4.59-1.07-6.06-3.07-2.44-3.34-1.72-8.04 1.63-10.48 6.76-4.94 19.14-12.08 39.69-18.03 23.92-6.91 52.76-10.42 85.73-10.42 4.14 0 7.5 3.36 7.5 7.5s-3.36 7.5-7.5 7.5c-73.66 0-106.06 17.87-116.57 25.56-1.33.98-2.88 1.45-4.42 1.45z"
            />
          </g>
        </svg>

        <svg
          v-if="index == 1"
          viewBox="0 0 375 213"
          xmlns="http://www.w3.org/2000/svg"
          class="arrow"
        >
          <g fill="#5e6eff">
            <path
              d="m361.89 186.65c-14.01 0-26.85-1.3-38.78-3.9-33.33-7.24-56.59-23.33-83.53-41.96-18.25-12.62-37.12-25.67-61.94-38.08-3.71-1.85-5.21-6.36-3.35-10.06 1.85-3.7 6.36-5.21 10.06-3.35 25.77 12.88 45.95 26.85 63.76 39.16 25.63 17.72 47.76 33.03 78.18 39.64 33.46 7.27 75.44 3.48 132.13-11.92 4-1.08 8.12 1.27 9.2 5.27 1.09 4-1.27 8.12-5.27 9.2-39.47 10.72-72.32 16-100.47 16z"
            />
            <path
              d="m95.53 107.43c-3.31 0-6.34-2.21-7.24-5.56-1.07-4 1.3-8.11 5.3-9.19l51.83-13.89-13.89-51.83c-1.07-4 1.3-8.11 5.3-9.19 4-1.07 8.11 1.3 9.19 5.3l15.31 57.14c.66 2.45.32 5.01-.95 7.21s-3.32 3.77-5.77 4.43l-57.14 15.31c-.65.17-1.3.26-1.95.26zm51.32-23.32h.01z"
            />
            <path
              d="m119.27 73.38c-1.29 0-2.6-.33-3.79-1.03-101.33-59.55-196.7-61.24-232.98-53.81-4.06.83-8.02-1.78-8.85-5.84s1.79-8.02 5.84-8.85c38.23-7.84 138.33-6.29 243.59 55.56 3.57 2.1 4.77 6.69 2.67 10.27-1.4 2.38-3.9 3.7-6.47 3.7z"
            />
          </g>
        </svg>

        <svg
          v-if="index == 2"
          viewBox="0 0 375 284"
          xmlns="http://www.w3.org/2000/svg"
          class="arrow"
        >
          <g fill="#5e6eff">
            <path
              d="m141.65 207.34c-1.96 0-3.92-.77-5.4-2.29-2.88-2.98-2.79-7.73.19-10.61l38.6-37.27-37.27-38.6c-2.88-2.98-2.79-7.73.19-10.6 2.98-2.88 7.73-2.79 10.6.19l41.1 42.56c3.64 3.77 3.53 9.79-.23 13.43l-42.56 41.1c-1.46 1.41-3.33 2.1-5.21 2.1zm37.21-46.21h.01z"
            />
            <path
              d="m143.53 164.17s-.07 0-.11 0c-61.24-.86-104.83-9.36-137.19-26.75-30.04-16.15-47.97-38.03-68.73-63.36-14.06-17.16-28.61-34.91-49.04-53.68-3.05-2.8-3.25-7.55-.45-10.6s7.55-3.25 10.6-.45c21.22 19.49 36.77 38.47 50.5 55.22 19.74 24.11 36.8 44.92 64.22 59.66 30.16 16.21 71.56 24.14 130.3 24.96 4.14.06 7.45 3.46 7.39 7.6-.06 4.11-3.4 7.39-7.5 7.39z"
            />
            <path
              d="m413.49 198.79c-1.48 0-2.98-.44-4.29-1.35-64.68-45.21-110.62-39.15-155.04-33.29-11.92 1.57-23.18 3.06-34.75 3.58-4.17.2-7.64-3.02-7.83-7.16s3.02-7.64 7.16-7.83c10.92-.49 21.87-1.94 33.46-3.47 45.06-5.95 96.14-12.69 165.6 35.86 3.4 2.37 4.22 7.05 1.85 10.44-1.46 2.09-3.79 3.2-6.15 3.2z"
            />
          </g>
        </svg>

        <svg
          v-if="index == 3"
          viewBox="0 0 375 182"
          xmlns="http://www.w3.org/2000/svg"
          class="arrow"
        >
          <g fill="#5e6eff">
            <path
              d="m110.74 100.39c-2.41 0-4.78-1.16-6.23-3.31-2.31-3.44-1.4-8.1 2.03-10.41l44.51-29.96-29.96-44.52c-2.31-3.44-1.4-8.1 2.03-10.41 3.44-2.31 8.1-1.4 10.41 2.03l33.03 49.08c1.42 2.1 1.93 4.63 1.44 7.13-.49 2.49-1.91 4.64-4.02 6.06l-49.08 33.03c-1.28.87-2.74 1.28-4.18 1.28z"
            />
            <path
              d="m-72 80.52c-3.13 0-6.06-1.98-7.11-5.11-1.32-3.93.79-8.18 4.72-9.5 10.36-3.48 20.49-7.89 31.21-12.55 41.68-18.15 88.92-38.71 169.07-11.18 3.92 1.35 6 5.61 4.66 9.53-1.35 3.92-5.61 6-9.53 4.66-74.64-25.64-117.13-7.14-158.22 10.74-11.02 4.8-21.44 9.33-32.41 13.02-.79.27-1.6.39-2.39.39z"
            />
            <path
              d="m423 181.5c-2.01 0-4.01-.8-5.49-2.39-20.33-21.85-58.86-45.47-111.4-68.31-55.74-24.22-106.01-38.71-120.09-42.57-3.99-1.1-6.34-5.22-5.25-9.22 1.1-3.99 5.22-6.34 9.22-5.25 61.01 16.74 189.38 62.33 238.51 115.12 2.82 3.03 2.65 7.78-.38 10.6-1.44 1.34-3.28 2.01-5.11 2.01z"
            />
          </g>
        </svg>
        <div class="step__content">
          <h2 class="step__header">{{ item.heading }}</h2>
          <div v-html="item.text" class="step__body" />
        </div>
      </li>
    </ol>
  </section>
</template>

<script>
import { background } from '@/mixins';
export default {
  name: 'BlockSteps',
  components: {},
  props: {
    block: [Array, Object]
  },
  mixins: [background]
};
</script>

<style lang="postcss">
.block-steps {
  position: relative;
  overflow: hidden;
  z-index: 1;
  @apply pb-32;
  &__container {
    @apply mx-auto;
    position: relative;
    z-index: 1;
    @apply pb-24;
    display: flex;
    flex-direction: column;
    @screen lg {
      @apply w-9/10;
      padding-top: 36rem;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 50px;
    }
  }
}
.arrows {
  display: none;
  @screen lg {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
  }
}

.arrow {
  @screen lg {
    display: none;
  }
}

.step {
  @apply font-ginto-regular;

  @screen lg {
    &:nth-child(2) {
      padding-top: 20%;
    }
    &:nth-child(3) {
      padding-top: 40%;
    }
    &:nth-child(4) {
      padding-top: 25%;
    }
  }
}

.step__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @apply w-9/10;
  margin-top: -3vh;
  padding-bottom: 5vh;
  @screen lg {
    width: 100%;
    padding-bottom: 0;
    margin-top: 0;
  }
}

.step__header {
  font-size: 28px;
  @apply font-ginto-black;
}

.step__body {
  font-size: 16px;
  p {
    margin-bottom: 20px;
  }
}
</style>
