<template>
  <section class="block-cta" :class="[background]">
    <h2>{{ block.title }}</h2>
    <div class="block-cta__cta" v-if="block.ctaGroup.length">
      <CtaItem
        :label="block.ctaGroup[0].label"
        :link="`${block.ctaGroup[0].link}`"
        :colour="block.ctaGroup[0].colour"
        :bubble="block.ctaGroup[0].bubble"
        :disabled="block.ctaGroup[0].disabled"
      />
    </div>
  </section>
</template>

<script>
import 'intersection-observer';
import { background } from '@/mixins';
import CtaItem from '@/components/Cta/CtaItem';
export default {
  name: 'BlockCta',
  components: {
    CtaItem
  },
  props: {
    block: [Array, Object]
  },
  mixins: [background],
  data() {
    return {
      intersectionOptions: {
        root: null,
        threshold: 0.5
      },
      observer: null,
      intersected: false
    };
  },
  methods: {
    intersectionCallback(entries) {
      const entry = entries[0];
      if (entry.intersectionRatio > 0) {
        this.observer.unobserve(entry.target);
        this.intersected = true;
      }
    },
    initObserver() {
      this.observer = new IntersectionObserver(
        this.intersectionCallback,
        this.intersectionOptions
      );
      this.observer.observe(this.$el);
    }
  },
  mounted() {
    this.initObserver();
  }
};
</script>

<style lang="postcss">
@import '../../styles/animate.css';

.block-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  @apply py-24;
  h2 {
    @apply font-ginto-black;
    @apply text-brand-darkerBlue;
    @apply mb-16;
    font-size: calc(28px + (56 - 28) * (100vw - 640px) / (1920 - 640));
  }
}
</style>
