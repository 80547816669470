<template>
  <div class="loader">
    <LogoEmblem />
  </div>
</template>

<script>
import LogoEmblem from '@/components/Logo/LogoEmblem';

export default {
  name: 'LoaderScreen',
  components: {
    LogoEmblem
  },
  mounted() {}
};
</script>

<style lang="postcss">
@keyframes loading-color {
  0% {
    fill: #000;
    transform: translate3d(0, 0px, 0);
  }
  100% {
    fill: #5e6eff;
    transform: translate3d(0, 10px, 0);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

.loader {
  /* transition-property: opacity;
  transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
  transition-duration: 800ms;
  transition-delay: 200ms; */
  @apply fixed;
  @apply h-full w-full;
  z-index: 99;
  @apply flex justify-center items-center;
  @apply pointer-events-none;
  @apply bg-brand-white;
  @apply opacity-100;
  animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
  animation-duration: 1000ms;
  animation-fill-mode: both;
  svg {
    width: 100px;
    height: 100px;
    animation-duration: 800ms;
    animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    animation-name: loading-color;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  &.loaded {
    @apply opacity-0;
  }
  &.unload {
    @apply opacity-100;
  }
  &.loading {
    animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    animation-duration: 800ms;
    animation-fill-mode: forwards;
    animation-name: fade;
  }
  &.unloading {
    animation-name: fade-out;
  }
}
</style>
