<template>
  <section>
    <FilterCore
      v-if="!getIsMobile && isFilterPage"
      :lists="[locations, category]"
    />

    <div class="block-card-entries-container">
      <div
        class="block-card-entries-wrapper"
        v-if="getIsFilterListLoaded && filteredList.length >= 1"
        :class="{ loaded: getIsFilterListLoaded }"
      >
        <div
          v-for="(item, index) in filteredList"
          :key="`card-item-${item.id}`"
          :class="[{ 'animate--pop-in': getIsFilterListLoaded }]"
          class="card-item-container"
          :style="`animation-delay: ${index * 100}ms`"
        >
          <router-link v-if="item.hasCaseStudy" :to="item.caseStudyLink">
            <CardItem :entry="item" />
          </router-link>
          <a :href="item.website" v-else>
            <CardItem :entry="item" />
          </a>
        </div>
      </div>

      <div class="block-card-entries-state" v-else>
        <h3>{{ loadingText }}</h3>
      </div>

      <div v-if="filteredList.length >= 1" class="block-card-entries-rest">
        <h3>Other charities we have funded</h3>
        <ul>
          <li v-for="item in restOfMobileEntries" :key="`card-item-${item.id}`">
            <router-link
              :to="`${item.hasCaseStudy ? item.caseStudyLink : item.website}`"
            >
              <span>{{ item.title }}</span>
              <IconArrow colour="#B2B2B2" width="12" />
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CardItem from '@/components/Card/CardItem';
import FilterCore from '@/components/Filter/FilterCore';
import IconArrow from '@/components/Icon/IconArrow';

export default {
  name: 'BlockCardEntries',
  components: {
    CardItem,
    IconArrow,
    FilterCore
  },
  props: {
    block: [Array, Object]
  },
  data() {
    return {
      entries: null,
      isLoading: true,
      category: {
        label: 'Category',
        items: [
          {
            label: 'Show only case studies',
            slug: 'case-study'
          },
          {
            label: 'Show only charities',
            slug: 'charities'
          }
        ]
      },
      locations: {
        label: 'Location',
        items: [
          { label: 'Westminster', slug: 'westminster' },
          { label: 'Chester', slug: 'chester' },
          { label: 'Rural Communities', slug: 'rural-communities' },
          { label: 'National', slug: 'National' }
        ]
      }
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getFilterLocation',
      'getFilterCategory',
      'getIsFilterListLoaded'
    ]),
    ...mapState({
      filterLocation: state => state.filterLocation,
      filterCategory: state => state.filterCategory
    }),
    isFilterPage() {
      return this.$route.params.slug === 'success-stories' ? true : false;
    },
    section() {
      return this.block.section;
    },
    loadingText() {
      return this.isLoading
        ? 'Loading results...'
        : 'Sorry there are no results';
    },
    categoryEntries() {
      if (this.entries) {
        return this.entries.filter(item => item.hasCaseStudy);
      }
      return [];
    },
    restOfMobileEntries() {
      return this.entries ? this.entries.slice(4, this.entries.length) : null;
    },
    filteredList() {
      if (!this.entries) return [];

      if (this.getIsMobile) {
        return this.categoryEntries;
      }

      if (this.filterCategory === 'case-study') {
        const arr = [...this.entries];
        return arr.filter(x => x.hasCaseStudy);
      } else if (this.filterCategory === 'charities') {
        const arr = [...this.entries];
        return arr.filter(x => !x.hasCaseStudy);
      } else {
        return [...this.entries];
      }
    }
  },
  watch: {
    filterLocation() {
      this.getContent();
    }
  },
  created() {
    this.getContent();
  },
  methods: {
    applyLocationFilter(locationSlug) {
      this.location = locationSlug;
    },
    getContent() {
      this.isLoading = true;
      // set api call
      const apiCall =
        this.block.section === 'charities'
          ? 'charities-filter'
          : this.block.section;
      // set query params
      const queryParams =
        this.getFilterLocation !== undefined
          ? `?location=${this.getFilterLocation}`
          : '';
      this.$http
        .get(`api/v1/${apiCall}.json${queryParams}`)
        .then(response => {
          this.entries = response.data.data;
          this.isLoading = false;
          this.$store.commit('setIsFilterListLoaded', true);
        })
        .catch(error => console.error(error));
    }
  }
};
</script>

<style lang="postcss">
@import '../../styles/animate.css';

.block-card-entries {
  &-container {
    @apply max-w-max mx-auto;
    @apply py-16;
    @screen sm {
      @apply py-32;
    }
  }
  &-wrapper {
    @apply flex flex-wrap;
    .card-item-container {
      @apply opacity-0;
      @apply mb-24;
      &:last-of-type {
        @apply mb-0;
      }
      @screen sm {
        @apply mr-24;
      }
      @screen md {
        width: calc(50% - 6rem);
      }
      @screen lg {
        width: calc(33.33% - 6rem);
      }
      @screen xl {
      }
      @screen xxl {
      }
      &:last-of-type {
        @apply mr-0;
      }
    }
    &.loaded {
      .card-item {
      }
    }
  }
  &-rest {
    @apply py-16;
    h3 {
      @apply text-center;
      @apply leading-tight;
      @apply max-w-sm mx-auto;
      @apply mb-16;
      font-size: calc(26px + (26 - 26) * (100vw - 640px) / (1920 - 640));
    }
    ul {
      li {
        font-size: calc(16px + (16 - 16) * (100vw - 640px) / (1920 - 640));
        @apply border-b border-brand-border;
        @apply py-3;
        &:first-of-type {
          @apply pt-0;
        }
        a {
          @apply w-full;
          @apply flex items-center justify-between;
        }
      }
    }
    @screen sm {
      @apply py-32;
      @apply hidden;
    }
  }
  &-state {
    @apply py-16;
    h3 {
      @apply text-center;
      @apply leading-tight;
      @apply max-w-sm mx-auto;
      @apply mb-16;
      font-size: calc(26px + (26 - 26) * (100vw - 640px) / (1920 - 640));
    }
    ul {
      li {
        font-size: calc(16px + (16 - 16) * (100vw - 640px) / (1920 - 640));
        @apply border-b border-brand-border;
        @apply py-3;
        &:first-of-type {
          @apply pt-0;
        }
        a {
          @apply w-full;
          @apply flex items-center justify-between;
        }
      }
    }
    @screen sm {
      @apply py-32;
    }
  }
}
</style>
