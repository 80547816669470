<template>
  <section class="block-case" :class="[background]" :style="[blockCaseHeight]">
    <div class="block-case__media">
      <div class="swiper-container--case" :style="[blockCaseHeight]">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in block.caseStudies"
            :key="`case-study-${index}`"
          >
            <img
              :src="item.images[0].src"
              :alt="item.images[0].title"
              :srcset="item.images[0].srcset"
              :style="[blockCaseHeight]"
            />
          </div>
        </div>
        <!-- If we need pagination -->
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev" @click="previousSlide"></div>
        <div class="swiper-button-next" @click="nextSlide"></div>
      </div>
    </div>
    <div class="block-case__content">
      <div class="block-case__content__container">
        <h4>
          <router-link to="/who-we-fund">
            Who We Fund
          </router-link>
        </h4>
        <h3 v-if="caseStudyDetails.title">{{ caseStudyDetails.title }}</h3>
        <div v-if="caseStudyDetails.body" v-html="caseStudyDetails.body"></div>
        <CtaItem
          colour="white"
          label="See the case study"
          :link="`case-study/${caseStudyDetails.slug}`"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { background } from '@/mixins';

import Swiper from 'swiper';

import CtaItem from '@/components/Cta/CtaItem';

export default {
  name: 'BlockCase',
  components: {
    CtaItem
  },
  props: {
    block: [Array, Object]
  },
  data: () => {
    return {
      carousel: null,
      options: {
        autoplay: true,
        grabCursor: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets'
        }
      },
      currentSlide: 0
    };
  },
  mixins: [background],
  computed: {
    ...mapGetters(['getIsMobile', 'getHeightHeader']),
    blockCaseHeight() {
      return {
        height: !this.getIsMobile
          ? `${window.innerHeight - this.getHeightHeader}px`
          : null
      };
    },
    caseStudyDetails() {
      return this.block.caseStudies
        ? this.block.caseStudies[this.currentSlide]
        : null;
    }
  },
  methods: {
    init() {
      this.carousel = new Swiper('.swiper-container--case', this.options);
    },
    currentIndex() {
      this.carousel.on('slideChange', () => {
        this.currentSlide = this.carousel.realIndex;
      });
    },
    previousSlide() {
      this.carousel.slidePrev();
    },
    nextSlide() {
      this.carousel.slideNext();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
      this.currentIndex();
    });
  }
};
</script>

<style lang="postcss">
@import 'swiper/css/swiper.min.css';

:root {
  --swiper-pagination-color: white;
  --swiper-theme-color: white;
  --swiper-navigation-size: 25px;
}

.swiper-button-prev,
.swiper-button-next {
  @apply px-16 py-0;
}

.block-case {
  @apply flex flex-wrap;
  @apply relative;
  @screen sm {
    @apply h-screen;
  }
  &__media {
    @apply w-full;
    @apply relative;
    @apply overflow-hidden;
    height: 50vh;
    @screen sm {
      @apply h-full;
      @apply w-10/12;
    }
    img {
      @apply object-cover;
      height: 50vh;
      @screen sm {
        @apply h-full;
      }
    }
    .swiper-pagination {
      bottom: 20px;
      @apply flex;
      @apply px-8;
      @screen sm {
        bottom: 0px;
        @apply px-16 py-32;
      }
      &-bullet {
        @apply bg-brand-white;
        @apply opacity-100;
        transition-property: opacity;
        transition-duration: 250ms;
        transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
        width: 14px;
        height: 14px;
        @apply bg-transparent;
        @apply border-brand-white border;
        @apply border-2;
        @apply mx-12;
        &-active {
          @apply bg-brand-white;
        }
      }
    }
  }
  &__content {
    @apply bg-brand-green;
    @apply w-full;
    @apply h-auto;
    @apply py-12;
    @screen sm {
      /* height: 60vh; */
      height: 450px;
      @apply p-16;
      @apply absolute bottom-0 right-0;
      @apply w-7/12;
      @apply z-10;
    }
    @screen xxl {
      @apply p-24;
      @apply w-5/12;
      /* height: 50vh; */
      /* max-height: 300px; */
      height: 550px;
    }
    &__container {
      @apply w-9/10 mx-auto;
      height: 50vh;
      @apply flex flex-col;
      @screen sm {
        @apply h-full;
        @apply max-w-2xl;
        @apply w-auto mx-0;
      }
      @screen xl {
        @apply max-w-4xl;
      }
      h4 {
        a {
          @apply p-2;
          @apply border-brand-lightGreen border-solid border;
          @apply inline-block;
          @apply text-brand-lightGreen font-ginto-regular;
          font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
          @apply mb-4;
          @screen sm {
            @apply p-4;
            @apply mb-8;
          }
        }
      }
      h3 {
        @apply text-brand-white font-ginto-black;
        font-size: calc(28px + (56 - 28) * (100vw - 640px) / (1920 - 640));
        @apply mb-8;
        @apply leading-tight;
      }
      p {
        @apply text-brand-white;
        font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
        &:last-of-type {
          @apply mb-16;
        }
      }
      .cta-item {
        @apply mt-auto;
      }
    }
  }
}
</style>
