<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 42.93 42.93"
  >
    <g id="Layer_1" data-name="Layer 1">
      <path
        fill="#ec6744"
        d="M0,21.46A21.34,21.34,0,0,1,21.23,0h.26A21.37,21.37,0,0,1,42.93,21.3v.16A21.37,21.37,0,0,1,21.66,42.93h-.17A21.34,21.34,0,0,1,0,21.73Zm41.49,0a19.9,19.9,0,0,0-19.76-20h-.24a19.94,19.94,0,0,0-20,19.82v.2a19.93,19.93,0,0,0,19.84,20h.21a19.89,19.89,0,0,0,20-19.78ZM28,32a.73.73,0,0,1,.81.63v0a.75.75,0,0,1-.8.72H15.86a.75.75,0,0,1-.81-.7v0a.71.71,0,0,1,.77-.67h3.07V19.83h-3a.75.75,0,0,1-.81-.7v0a.73.73,0,0,1,.78-.67h8.71a.46.46,0,0,1,.46.45V32Zm-9.9-20.94A3.77,3.77,0,0,1,21.89,7.3H22A3.76,3.76,0,0,1,25.77,11v.05a3.77,3.77,0,0,1-3.72,3.84H22a3.8,3.8,0,0,1-3.85-3.74V11.1Zm6.2,0a2.31,2.31,0,0,0-2.25-2.35H22A2.31,2.31,0,0,0,19.57,11v.13a2.32,2.32,0,0,0,2.24,2.4H22a2.31,2.31,0,0,0,2.36-2.27V11.1ZM20.79,32H23.1a.46.46,0,0,0,.46-.45V20.14a.46.46,0,0,0-.46-.45H20.79a.46.46,0,0,0-.46.45V31.57a.46.46,0,0,0,.45.46Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconInfo'
};
</script>

<style lang="postcss" scoped></style>
