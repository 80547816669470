<template>
  <BlockWave
    v-bind="setWave(block, 1, lastUuid[0])"
    @mask="handleMask(1, $event)"
  >
    <BlockWave
      v-bind="setWave(block, 2, lastUuid[1])"
      @mask="handleMask(2, $event)"
    >
      <slot />
    </BlockWave>
  </BlockWave>
</template>

<script>
import { maskBus } from '@/mixins';
import BlockWave from '@/components/Block/BlockWave';

export default {
  name: 'BlockMultiWave',

  components: {
    BlockWave
  },

  props: {
    block: {
      type: Object
    },
    lastMask: {
      type: Object,
      default: null
    }
  },

  mixins: [maskBus],

  watch: {
    lastMask: {
      immediate: true,
      handler(newVal, oldVal) {
        this.setMask(0, this.lastMask);

        if (!oldVal) return;

        if (newVal.update !== oldVal.update) {
          this.update();
        }
      }
    }
  },

  computed: {
    lastUuid() {
      return [0, this.block.wave['1'].waveEnable ? 1 : 0];
    }
  },

  methods: {
    /**
     * Hnadle marco mask emision
     */
    handleMask(uuid, mask) {
      if (this.block.wave['1'].waveEnable && this.block.wave['2'].waveEnable) {
        if (uuid === 1) this.setMask(1, mask);
        if (uuid === 2) this.$emit('mask', mask);
        return;
      }
      if (this.block.wave['1'].waveEnable || this.block.wave['2'].waveEnable) {
        if (uuid === 1 && this.block.wave['1'].waveEnable)
          this.$emit('mask', mask);
        if (uuid === 2 && this.block.wave['2'].waveEnable)
          this.$emit('mask', mask);
        return;
      }
      return;
    }
  }
};
</script>
