<template>
  <section class="block-video" :class="[background]">
    <VideoPlayer class="z-40" v-bind="media" />
  </section>
</template>

<script>
import { background } from '@/mixins';
import VideoPlayer from '@/components/Video/VideoPlayer';

export default {
  name: 'BlockVideo',
  components: {
    VideoPlayer
  },
  props: {
    block: [Array, Object],
    media: [Array, Object]
  },
  mixins: [background]
};
</script>

<style lang="postcss" scoped>
.block-video {
  @apply flex items-center justify-center;
  @apply p-16;
  @screen sm {
    @apply min-h-screen;
    @apply py-32;
  }
}
</style>
