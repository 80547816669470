var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"block-option-cards",class:[_vm.background]},[_c('div',{staticClass:"block-option-cards__wrapper"},[_c('div',{staticClass:"block-option-cards__tab-group"},_vm._l((_vm.tabTitles),function(title,index){return _c('div',{key:("tab-title-" + index),staticClass:"block-option-cards__tab-group__item",class:[_vm.currentIndex == index ? 'active' : ''],on:{"click":function($event){_vm.currentIndex = index}}},[_c('h4',[_vm._v(_vm._s(title))])])}),0),_c('div',{staticClass:"block-option-cards__container"},[_c('div',{staticClass:"block-option-cards__group"},_vm._l((_vm.block.optionGroup),function(option,index){return _c('div',{key:("option-card-" + index),staticClass:"block-option-cards__group__item",class:[
            _vm.currentIndex == index ? 'active' : '',
            { 'animate--slide-up': _vm.intersected }
          ],style:(("animation-delay: " + (index * 250) + "ms")),on:{"mouseover":function($event){[
              !_vm.getIsMobile ? (_vm.howToApplyIndex = index) : '',
              !_vm.getIsMobile ? (_vm.hover = true) : ''
            ]},"mouseout":function($event){[
              !_vm.getIsMobile ? (_vm.howToApplyIndex = null) : '',
              !_vm.getIsMobile ? (_vm.hover = false) : ''
            ]}}},[_c('div',{staticClass:"block-option-cards__group__item__icon"},[_c('img',{attrs:{"src":option.icon,"alt":"Icon"}})]),_c('div',{staticClass:"block-option-cards__group__item__heading"},[_c('h3',{domProps:{"innerHTML":_vm._s(option.title)}}),_c('h4',{domProps:{"innerHTML":_vm._s(
                _vm.howToApplyIndex === index && option.price
                  ? 'How to apply'
                  : option.price
              )}})]),_c('div',{staticClass:"block-option-cards__group__item__container"},[_c('ul',{class:[
                _vm.howToApplyIndex === index && _vm.hover ? 'hidden' : 'block'
              ]},[(option.fundingFocus)?_c('li',[_c('h5',[_vm._v("Funding focus")]),_c('p',[_vm._v(_vm._s(option.fundingFocus))])]):_vm._e(),(option.fundingAreas)?_c('li',[_c('h5',[_vm._v("Funding areas")]),_c('p',[_vm._v(_vm._s(option.fundingAreas))])]):_vm._e(),(false)?_c('li',[_c('h5',[_vm._v("Exclusions")]),_c('p',[_vm._v(_vm._s(option.exclusions))])]):_vm._e()]),_c('div',{staticClass:"message",class:[
                _vm.howToApplyIndex == index && _vm.hover ? 'block' : 'hidden'
              ],domProps:{"innerHTML":_vm._s(option.howToApply)}}),_c('button',{staticClass:"read-more",on:{"click":function($event){[(_vm.howToApplyIndex = index), (_vm.hover = !_vm.hover)]}}},[(!_vm.hover)?_c('div',[_c('span',[_vm._v("Read More")]),_c('IconPlus')],1):_c('div',[_c('span',[_vm._v("Read Less")]),_vm._v(" "),_c('IconMinus')],1)])]),_c('div',{staticClass:"block-option-cards__group__item__cta"},[_c('CtaItem',{attrs:{"label":option.ctaLabel,"link":("" + (option.ctaLink)),"bubble":true,"colour":"black","disabled":option.disabled}})],1)])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }