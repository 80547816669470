<template>
  <section class="embed-grant-charts">
    <div class="embed-grant-charts__1">
      <EmbedGrantChart1 />
    </div>
    <div class="embed-grant-charts__2">
      <EmbedGrantChart2 />
    </div>
  </section>
</template>

<script>
import EmbedGrantChart1 from '@/components/Embed/EmbedGrantChart1';
import EmbedGrantChart2 from '@/components/Embed/EmbedGrantChart2';
export default {
  name: 'EmbedGrantCharts',
  components: {
    EmbedGrantChart1,
    EmbedGrantChart2
  }
};
</script>

<style lang="postcss">
.embed-grant-charts {
  @apply flex flex-wrap justify-between;
  @apply w-full mx-auto;
  max-width: 80%;
  @apply pb-16;
  @screen sm {
    @apply pb-32;
  }
  &__1 {
    @apply z-30;
    @apply w-full;
    @screen sm {
      width: calc(50% - 16rem);
      @apply mx-8;
    }
  }
  &__2 {
    @apply z-30;
    @apply w-full;
    @screen sm {
      width: calc(50% - 16rem);
      @apply mx-8;
    }
  }
}
</style>
