<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.5"
    height="10.5"
    viewBox="0 0 10.5 10.5"
  >
    <g transform="translate(0 3.143)">
      <line
        x2="10.5"
        transform="translate(0 2.107)"
        fill="none"
        :stroke="colour"
        stroke-miterlimit="10"
        stroke-width="1"
      />
      <line
        x2="10.5"
        transform="translate(5.25 -3.143) rotate(90)"
        fill="none"
        :stroke="colour"
        stroke-miterlimit="10"
        stroke-width="1"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconPlus',
  props: {
    colour: {
      type: String,
      default: '#000'
    }
  }
};
</script>

<style></style>
