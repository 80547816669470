<template>
  <section class="block-details" :class="[background]">
    <div class="block-details__header">
      <h2>{{ block.title }}</h2>
    </div>
    <div
      v-if="block.layoutType === 'dropdowns'"
      class="block-details__container"
    >
      <details
        v-for="(item, index) in block.details"
        :key="`paragraph-${index}`"
        class="details"
      >
        <summary class="details__bar"
          >{{ item.heading }}
          <svg viewBox="0 0 22 14" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m11 13.18c-.81 0-1.6-.33-2.16-.92l-7.78-8.05c-.77-.79-.75-2.06.05-2.83s2.06-.75 2.83.05l7.06 7.31 7.06-7.31c.77-.79 2.03-.82 2.83-.05.79.77.82 2.03.05 2.83l-7.78 8.05c-.56.58-1.35.92-2.16.92z"
            /></svg
        ></summary>
        <div v-html="item.text" class="details__content" />
      </details>
    </div>
    <div v-else class="block-details__cards">
      <div
        v-for="(item, index) in block.details"
        :key="`paragraph-${index}`"
        class="display-cards"
      >
        <h2 class="display-cards--header">{{ item.heading }}</h2>
        <div v-html="item.text" class="display-cards--content" />
      </div>
    </div>
  </section>
</template>

<script>
import { background } from '@/mixins';
export default {
  name: 'BlockDetails',
  components: {},
  props: {
    block: [Array, Object]
  },
  mixins: [background]
};
</script>

<style lang="postcss">
summary {
  cursor: pointer;
  &::-webkit-details-marker {
    display: none;
  }
}

.block-details {
  position: relative;
  z-index: 1;
  &__header {
    h2 {
      @apply font-ginto-black;
      @apply mb-8;
      @apply pt-24;
      @apply pb-16;
      font-size: calc(32px + (56 - 32) * (100vw - 640px) / (1920 - 640));
      text-align: center;
    }
  }
  &.bg-brand-cream {
    h2 {
      color: var(--Cornflower, #5e6eff);
    }
  }
  &.bg-brand-lighterBlue {
    h2 {
      color: white;
    }
  }
  &__container {
    @apply w-9/10;
    @apply max-w-screen-xl mx-auto;
    /* @apply py-32; */
    display: grid;
    @screen lg {
      grid-template-columns: repeat(2, 1fr);
    }
    column-gap: 20px;
    row-gap: 40px;
    @screen sm {
      @apply pb-24;
    }
    position: relative;
    z-index: 1;
  }

  &__cards {
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 14px;
    @apply w-9/10;
    @apply max-w-screen-xl mx-auto;
    @apply pb-24;
    @screen sm {
      @apply pb-24;
    }
  }
}
.display-cards {
  border-radius: 14px;
  background: var(--Cornflower, #5e6eff);
  padding: 2em;

  width: 100%;

  @screen md {
    width: 33.33%;
    flex: 1;
  }

  h2 {
    font-size: 24px;
    @apply font-ginto-bold;
    @apply pb-4;
    color: white;
    text-align: center;
  }
  p {
    @apply font-ginto-regular;
    @apply mb-8;
    font-size: 16px;
    line-height: 1.4em;
    text-align: center;
    color: white;
  }
}
.details {
  border-radius: 4px;
  overflow: hidden;
  @screen sm {
    @apply flex-row items-start justify-between;
  }
}
.details__bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  font-size: 24px;
  @apply font-ginto-bold;
  background-color: white;

  svg {
    width: 20px;
    height: 14px;
  }
}
.details__content {
  background-color: white;
  padding: 16px 20px;
  position: relative;
  z-index: 1;
  font-size: 16px;
  h3 {
    margin-bottom: 20px;
    font-weight: 700;
  }
  p {
    margin-bottom: 40px;
  }
  ul {
    @apply ml-8;
  }
  li {
    list-style-type: disc;
    /* list-style-position: inside; */
    margin-bottom: 20px;
  }
}
</style>
