<template>
  <section class="block-paragraphs bg-brand-cream">
    <div class="block-paragraphs__container">
      <h2 v-if="block.heading" class="block-paragraphs__heading">
        {{ block.heading }}
      </h2>
      <div class="block-paragraphs__content">
        <div
          v-for="(item, index) in block.textGroup"
          :key="`text-group-${index}`"
          class="block-paragraphs__item"
          v-html="item.content"
        />
      </div>
    </div>
    <div class="block-paragraphs__curve" :class="[nextcolour]" />
  </section>
</template>

<script>
import { background, nextcolour } from '@/mixins';
export default {
  name: 'BlockParagraphs',
  props: {
    block: [Array, Object]
  },
  mixins: [background, nextcolour]
};
</script>

<style lang="postcss">
.block-paragraphs {
  overflow: hidden;
  @apply flex flex-wrap;
  position: relative;
  padding-bottom: 10vh;
  &__heading {
    @apply font-ginto-black;
    @apply mb-16;
    font-size: calc(20px + (36 - 20) * (100vw - 640px) / (1920 - 640));
    @apply leading-snug;
    @screen lg {
      width: 50%;
    }
  }
  &__container {
    @apply w-9/10;
    @apply mx-auto;
    @apply py-32;
    @apply z-40;
  }
  &__content {
    @screen lg {
      @apply grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
  }

  &__item {
    font-size: 16px;
    @apply flex;
    &:first-child {
      grid-column: 2;
    }
    @screen lg {
      @apply pb-16;
    }
  }

  &__curve {
    @apply absolute;
    @apply pointer-events-none;
    border-radius: 100%;
    bottom: 0;
    left: 0;
    height: 25vh;
    width: 200vw;
    transform: translate(-50vw, 20vh);
    @screen lg {
      height: 30vh;
      width: 114vw;
      transform: translate(-7vw, 20vh);
    }
  }
}
</style>
