<template>
  <div ref="container">
    <svg
      v-if="enabled && (maskData === null || !maskData.pending)"
      class="absolute z-30 pointer-events-none select-none"
      :style="styleSvg"
      viewBox="0 0 1920 1080"
      :preserveAspectRatio="
        `x${alignmentLockUpTable[_alignment.x]}Y${
          alignmentLockUpTable[_alignment.y]
        } ${_cropMode ? 'meet' : 'slice'}`
      "
    >
      <path ref="path" :style="stylePath" :d="wavePath" />
    </svg>
    <slot />
  </div>
</template>

<script>
import 'intersection-observer';
import { debounce } from 'lodash';
import { mapState } from 'vuex';
import { bind, unbind } from '@/utils/eventListener';
import { isEmptyObject } from '@/utils/types';

const wavePaths = {
  1: 'M-40.6,128c227.6,0,412.1,184.4,412.1,412S187,952-40.6,952',
  2: 'M2207.4,296.9c-160.9,160.9-421.8,160.9-582.7,0s-160.9-421.8,0-582.7',
  3: 'M2005.9,572.3c0,0-290.7,382.4-688.3,350.4s-506.8-375-543.4-460c-20.1-46.8-71.4-197.4-264.8-273.4s-414-24.3-595.3,151.6',
  4: 'M2005.9,531.8C1852.2,430.1,1636.1,327,1402,366.3c-294.8,49.5-464,269-801.1,343.2c-169,36.8-401.6-1.7-686.7-176.8',
  5: 'M2005.9,893.3C1250,1021.6,1181.4,49.2,484.3,188.7C189.5,247.7,49,504.3-74.2,668.3'
};

const alignmentLockUpTable = {
  left: 'Min',
  center: 'Mid',
  right: 'Max'
};

export const fallback = { pending: true };

export default {
  name: 'BlockWave',

  props: {
    enabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 1,
      validator: val => [1, 2, 3, 4, 5].indexOf(val) !== -1
    },
    position: {
      // X and Y vals that represent a % shift
      type: Object,
      default: () => ({
        x: 0,
        y: 0
      }),
      validator: val => isEmptyObject(val) || 'x' in val || 'y' in val
    },
    mobilePosition: {
      // X and Y vals that represent a % shift
      type: Object,
      default: () => ({
        x: 0,
        y: 0
      }),
      validator: val => isEmptyObject(val) || 'x' in val || 'y' in val
    },
    color: {
      type: String,
      default: '#FFFFFF'
    },
    duration: {
      type: Number,
      default: 5
    },
    reverse: {
      type: Boolean,
      default: false
    },
    alignment: {
      type: Object,
      default: () => ({
        x: 'center',
        y: 'center'
      }),
      validator: val => isEmptyObject(val) || 'x' in val || 'y' in val
    },
    rotate: {
      type: Number,
      default: 0
    },
    cropMode: {
      // When set to true, set preserveAspectRatio to 'meet'
      type: Boolean,
      default: false
    },
    maskData: {
      type: Object,
      default: null
    },
    update: {
      type: Number,
      default: 0
    },
    overrideOverflowHeight: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    h: 0,
    w: 0,
    scale: 1,
    pathCalculating: false,
    animationSetting: true,
    alignmentLockUpTable, // Bring into context
    strokeDasharray: 0,
    strokeDashoffset: 0
  }),

  computed: {
    ...mapState(['isMobile']),

    mask() {
      const {
        type,
        position,
        mobilePosition,
        alignment,
        rotate,
        cropMode,
        duration,
        reverse,
        h,
        w,
        $refs: { container }
      } = this;

      return {
        type,
        position,
        mobilePosition,
        alignment,
        rotate,
        cropMode,
        duration,
        reverse,
        h,
        w,
        container,
        pending: false
      };
    },

    // Computed props
    _type() {
      return this.maskOrProp('type');
    },
    _position() {
      const { isMobile } = this;

      if (this.maskData !== null && this.maskData.pending === false) {
        const { x, y } = isMobile
          ? this.maskData.mobilePosition
          : this.maskData.position;

        return {
          x: (this._w / 100) * x * this.scale,
          y: (this._h / 100) * y * this.scale - this._h * this.scale
        };
      }

      const { x, y } = isMobile ? this.mobilePosition : this.position;
      const { h, w } = this;

      return {
        x: (w / 100) * x * this.scale,
        y: (h / 100) * y * this.scale
      };
    },
    _duration() {
      return this.maskOrProp('duration');
    },
    _reverse() {
      return this.maskOrProp('reverse');
    },
    _alignment() {
      return {
        x: 'center',
        y: 'center',
        ...this.maskOrProp('alignment')
      };
    },
    _rotate() {
      return this.maskOrProp('rotate');
    },
    _cropMode() {
      return this.maskOrProp('cropMode');
    },
    _h() {
      return this.maskOrProp('h');
    },
    _w() {
      return this.maskOrProp('w');
    },
    _container() {
      return this.maskData && !this.maskData.pending
        ? this.maskData.container
        : this.$refs.container;
    },
    wavePath() {
      return wavePaths[this._type];
    },

    // Style Objects
    styleSvg() {
      const { isMobile, overrideOverflowHeight, h, _h, _w } = this;

      return {
        height: `${!isMobile && overrideOverflowHeight ? h : _h}px`,
        width: `${_w}px`
      };
    },
    stylePath() {
      const {
        isMobile,
        pathCalculating,
        color,
        _position: { x, y },
        _rotate,
        animationSetting,
        _duration,
        strokeDasharray,
        strokeDashoffset
      } = this;

      let strokeWidth = isMobile ? 15 : 30;

      if (pathCalculating) {
        strokeWidth = 1;
      }

      return {
        strokeWidth,
        strokeDasharray,
        strokeDashoffset,
        stroke: color,
        transform: `translate(${x}px, ${y}px) rotate(${_rotate}deg)`,
        transitionDuration:
          animationSetting || pathCalculating ? '0s' : `${_duration}s`
      };
    },

    /**
     * Remove circular references
     */
    nonCircularMaskData() {
      const newMaskData = {};
      Object.assign(newMaskData, this.maskData);
      delete newMaskData.container;
      return newMaskData;
    }
  },

  watch: {
    /**
     * Emits the mask. Guards against recursive events and circular references
     */
    mask: {
      immediate: true,
      handler(newVal, oldVal) {
        // wait for h and w to be avalible
        const { container } = newVal;

        const newValShallow = {};
        Object.assign(newValShallow, newVal);
        delete newValShallow.container;

        const oldValShallow = {};
        Object.assign(oldValShallow, oldVal);
        delete oldValShallow.container;

        const newMask = JSON.stringify(newValShallow);
        const oldMask = JSON.stringify(oldValShallow);

        if ((this.h !== 0 || this.w !== 0) && newMask !== oldMask) {
          const newValMask = { ...JSON.parse(newMask), container };
          this.$emit('mask', newValMask);
        }
      }
    },

    /**
     * Side effects
     */
    nonCircularMaskData: {
      deep: true,
      async handler(newVal, oldVal) {
        if (oldVal && oldVal.pending && !newVal.pending) {
          await this.sizeHandler();
          this.bindAnimation();
        }
      }
    },

    /**
     * A brute force methode to recalculate layout changes
     */
    update(val) {
      if (val) this.sizeHandler();
    }
  },

  created() {
    this.$emit('mask', fallback);
  },

  async mounted() {
    this.debounceSizeHandler = debounce(this.sizeHandler, 150);
    bind(window, 'resize', this.debounceSizeHandler);
    await this.sizeHandler();

    if (this.maskData === null || !this.maskData.pending) this.bindAnimation();
  },

  beforeDestroy() {
    unbind(window, 'resize', this.debounceSizeHandler);
    if (this.observer) this.observer.disconnect();
  },

  methods: {
    /**
     * Handles everything to do  with sizng that affects layout
     */
    async sizeHandler() {
      // Fonts loaded detection or primative await
      if ('fonts' in document) await document.fonts.ready;
      else await new Promise(resolve => setTimeout(() => resolve(), 25));

      const container = this.$refs.container;
      this.h = container.offsetHeight;
      this.w = container.offsetWidth;

      if (!this.enabled || (this.maskData && this.maskData.pending)) return;

      // await 3 clock cycles to get the correct alignment on resize
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();

      this.pathCalculating = true;
      await this.$nextTick();

      const path = this.$refs.path;
      this.scale = path.getBBox().width / path.getBoundingClientRect().width;

      await this.$nextTick();

      this.strokeDasharray = this.getTotalLength();

      this.pathCalculating = false;

      // Allow that to propergate
      await this.$nextTick();
    },

    /**
     * Returns either mask or prop data depending on wether component is part of
     * a mask group
     */
    maskOrProp(key) {
      // Mask data exists and isnt pending
      return this.maskData && !this.maskData.pending
        ? this.maskData[key]
        : this[key];
    },

    /**
     * Sets up and binds the animation to the page
     */
    async bindAnimation() {
      if (!this.enabled) return;

      this.animationSetting = true;

      const pathLength = this.getTotalLength();
      this.strokeDashoffset = this._reverse ? pathLength : -pathLength;

      await this.$nextTick();

      this.animationSetting = false;

      this.observer = new IntersectionObserver(this.animate, {
        rootMargin: '-25%'
      });

      this.observer.observe(this._container);
    },

    /**
     * The animate function, calling this will animate the path onto the screen
     * provided it is within view
     * @param {IntersectionObserverEntry[]}
     */
    animate([entry]) {
      if (!entry.isIntersecting) return;

      this.observer.disconnect();

      this.$refs.path.style.strokeDashoffset = 0;
    },

    /**
     * Returns the adjusted path length
     */
    getTotalLength() {
      return this.$refs.path.getTotalLength() * (1 / this.scale);
    }
  }
};
</script>

<style scoped>
path {
  fill: none;
  vector-effect: non-scaling-stroke;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  transition-duration: 0s;
  transition-property: stroke-dashoffset;
}
</style>
