<template>
  <div class="icon-burger-menu" :class="getIsMenuOpen ? 'is-active' : ''">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.059"
      height="13.221"
      viewBox="0 0 14.059 13.221"
    >
      <g transform="translate(-22 -21)">
        <line x2="14.059" fill="none" stroke="#000" stroke-width="1" id="top" />
        <line
          x2="14.059"
          fill="none"
          stroke="#000"
          stroke-width="1"
          id="middle"
        />
        <line
          x2="14.059"
          fill="none"
          stroke="#000"
          stroke-width="1"
          id="bottom"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'IconBurgerMenu',
  computed: {
    ...mapGetters(['getIsMenuOpen'])
  }
};
</script>

<style lang="postcss" scoped>
.icon-burger-menu {
  svg {
    #top,
    #middle,
    #bottom {
      transition-duration: 250ms;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    }
    #top {
      transform: rotate(0deg) translateX(22px) translateY(21.5px);
    }
    #middle {
      transform: rotate(0deg) translateX(22px) translateY(27.61px);
    }
    #bottom {
      transform: rotate(0deg) translateX(22px) translateY(33.721px);
    }
  }

  &.is-active {
    svg {
      #top {
        transform: rotate(45deg) translateX(33px) translateY(0px);
      }
      #middle {
        @apply opacity-0;
      }
      #bottom {
        transform: rotate(-45deg) translateX(-7px) translateY(40px);
      }
    }
  }
}
</style>
