<template>
  <section
    class="block-split"
    :class="[block.options.persons ? 'persons' : '']"
  >
    <h2 class="z-40">{{ block.title }}</h2>
    <div class="block-split__intro" v-html="block.body"></div>
    <div class="block-split__group">
      <div
        class="block-split__group__item"
        :class="[
          item.isIcon ? 'icon' : '',
          { 'animate--slide-up': intersected }
        ]"
        v-for="(item, index) in block.textImageGroup"
        :key="`text-image-item${index}`"
        :style="`animation-delay: ${index * 500}ms`"
      >
        <img
          v-if="item.icon"
          :src="item.icon"
          :class="item.isIcon ? 'icon' : ''"
        />
        <h4 v-if="item.heading" v-html="item.heading"></h4>
        <div class="block-split__group__item__text" v-html="item.body"></div>
      </div>
    </div>
    <div class="block-split__cta z-40" v-if="block.ctaGroup.length">
      <CtaItem
        :label="block.ctaGroup[0].label"
        :link="`${block.ctaGroup[0].link}`"
        :colour="block.ctaGroup[0].colour"
        :bubble="block.ctaGroup[0].bubble"
        :disabled="block.ctaGroup[0].disabled"
      />
    </div>
  </section>
</template>

<script>
import 'intersection-observer';

import CtaItem from '@/components/Cta/CtaItem';
export default {
  name: 'BlockSplit',
  components: {
    CtaItem
  },
  props: {
    block: [Array, Object]
  },
  data() {
    return {
      intersectionOptions: {
        root: null,
        threshold: 0.5
      },
      observer: null,
      intersected: false
    };
  },
  methods: {
    intersectionCallback(entries) {
      const entry = entries[0];
      if (entry.intersectionRatio > 0) {
        this.observer.unobserve(entry.target);
        this.intersected = true;
      }
    },
    initObserver() {
      this.observer = new IntersectionObserver(
        this.intersectionCallback,
        this.intersectionOptions
      );
      this.observer.observe(this.$el);
    }
  },
  mounted() {
    this.initObserver();
  }
};
</script>

<style lang="postcss">
@import '../../styles/animate.css';

.block-split {
  @apply flex flex-col items-center justify-center;
  @apply py-16;
  @apply bg-brand-grey;
  @apply px-12;
  @apply px-12;
  @screen sm {
    @apply py-32;
    @apply min-h-screen;
  }
  &__intro {
    @apply z-40;
    @apply mb-16;
    @apply max-w-md;
    @screen sm {
      @apply mb-0;
      @apply max-w-none;
    }
    p {
      @apply text-center;
    }
  }
  h2 {
    @apply font-ginto-black;
    @apply mb-16;
    font-size: calc(28px + (56 - 28) * (100vw - 640px) / (1920 - 640));
  }
  h3 {
    @apply font-ginto-regular text-center;
    font-size: calc(12px + (20 - 12) * (100vw - 640px) / (1920 - 640));
  }
  &__group {
    @apply z-40;
    @apply flex items-start justify-evenly flex-wrap;
    @apply w-full max-w-max;
    @screen sm {
      @apply py-32;
      @apply max-w-screen-xl;
    }
    @screen xl {
      @apply max-w-screen-xxl;
    }
    &__item {
      @apply flex flex-wrap flex-col;
      @apply w-full;
      @apply mb-8;
      @apply opacity-0;
      @screen sm {
        @apply max-w-xl;
        @apply w-1/3;
        @apply mb-0;
      }
      @screen xxl {
        @apply max-w-3xl;
      }
      &.icon {
        @apply w-full;
        @screen sm {
          @apply w-1/4;
        }
      }
      &:last-of-type {
        @screen sm {
          @apply pb-0;
          @apply mb-0;
        }
      }
      img {
        @apply w-full h-full;
        &.icon {
          @apply mx-auto;
          width: 50px;
          height: 50px;
          @apply mb-8;
          @screen sm {
            width: 100px;
            height: 100px;
          }
        }
      }
      h4 {
        @apply font-ginto-black text-center;
        @apply mb-12;
        font-size: calc(16px + (32 - 16) * (100vw - 640px) / (1920 - 640));
        @apply leading-tight;
      }
      &__text {
        @apply font-ginto-regular text-center;
        font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
      }
    }
  }
  &.persons {
    h4 {
      @apply font-ginto-regular;
      font-size: calc(16px + (26 - 16) * (100vw - 640px) / (1920 - 640));
    }
    .block-split__group__item {
      @apply px-8;
      @apply mb-16;
      @screen sm {
        @apply px-0;
      }
    }
    img {
      height: 180px;
      width: 180px;
      @apply mx-auto;
      @apply rounded-full;
      @apply mb-8;
      @screen sm {
        @apply mb-16;
        @apply w-auto h-auto;
      }
    }
  }
}
</style>
