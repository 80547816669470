<template>
  <button :class="[textColour, 'button-submit']" id="mc-embedded-subscribe">
    <div>
      <span>{{ label }}</span>
      <IconArrow :colour="arrowColour" :direction="'next'" />
    </div>
  </button>
</template>

<script>
import IconArrow from '@/components/Icon/IconArrow';

export default {
  name: 'ButtonReadMore',
  components: {
    IconArrow
  },
  props: {
    colour: String,
    label: String
  },
  computed: {
    textColour() {
      return `text-brand-${this.colour}`;
    },
    arrowColour() {
      let colour = '';
      for (let [key, value] of Object.entries(this.$colours)) {
        if (key === this.colour) colour = value;
      }
      return colour;
    }
  }
};
</script>

<style lang="postcss">
.button-submit {
  @apply py-8;
  @apply flex;
  @apply mx-auto;
  div {
    @apply flex items-center;
    span {
      @apply font-ginto-regular;
      @apply flex items-center;
      font-size: calc(16px + (20 - 16) * (100vw - 640px) / (1920 - 640));
    }
  }
  .icon-arrow {
    @apply mt-2;

    .arrow-prev {
      @apply mr-8;
    }
    .arrow-next {
      @apply ml-8;
      transition-duration: 500ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
  img {
    @apply inline-block;
    width: 32px;
    @screen sm {
      @apply w-auto;
    }
  }
  @apply appearance-none outline-none;
  &.bubble {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    @apply py-8 px-16;
    @apply bg-brand-white;
    border-radius: 8px;
    @apply border-brand-black border;
  }
  &:hover {
    .arrow-next {
      transform: translateX(1.25rem);
    }
  }
}
</style>
