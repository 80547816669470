<template>
  <!-- <router-link   -->
  <router-link :to="`/news/${entry.slug}`" class="card-news" v-if="entry">
    <img
      :src="entry.cover.src"
      :alt="entry.cover.alt"
      :srcset="entry.cover.srcset"
    />
    <div class="card-news__content">
      <span v-html="entry.date"></span>
      <h3 v-html="entry.title"></h3>
      <div v-html="entry.summary"></div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'CardNews',
  props: {
    entry: [Array, Object]
  }
};
</script>

<style lang="postcss">
.card-news {
  @apply text-center;
  @apply mb-16;
  @apply block;
  img {
    transition-property: filter;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    filter: brightness(1);
  }
  &:hover {
    img {
      filter: brightness(1.1);
    }
  }
  @screen sm {
    @apply mb-48;
    max-width: calc(50% - 4rem);
  }
  &__content {
    @apply py-8;
    @apply mx-auto;
    @apply w-9/12;
    span {
      @apply font-ginto-regular;
      @apply leading-loose;
      font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
    }
    h3 {
      @apply font-ginto-regular;
      @apply mb-8;
      font-size: calc(16px + (26 - 16) * (100vw - 640px) / (1920 - 640));
    }
    p {
      @apply font-ginto-regular;
      font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
    }
  }
}
</style>
