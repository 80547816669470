<template>
  <section class="filter-core">
    <div class="filter-core__container" v-if="lists">
      <FilterItem
        v-for="(list, index) in lists"
        :key="`${list.label}-${index}`"
        :label="list.label"
        :items="list.items"
      />
    </div>
  </section>
</template>

<script>
import FilterItem from '@/components/Filter/FilterItem';

export default {
  name: 'FilterCore',
  components: {
    FilterItem
  },
  props: {
    lists: {
      type: [Array, Object],
      default: null
    }
  }
};
</script>

<style lang="postcss" scoped>
.filter-core {
  @apply bg-brand-lightGreen;
  @apply text-brand-white;
  @apply py-16;
  &__container {
    @apply max-w-max mx-auto w-full;
    @apply flex flex-col;
    @screen sm {
      @apply flex-row justify-center;
    }
  }
}
</style>
