<template>
  <div class="icon-download">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.793"
      height="11.131"
      viewBox="0 0 9.793 11.131"
    >
      <path
        id="Path_1554"
        data-name="Path 1554"
        d="M303.393-497.008v3.142H293.6v-3.142h1v2.172h7.788v-2.172ZM299-498.278l2.74-2.873.769.768-4.01,4.012-4.012-4.012.769-.768,2.741,2.873V-505h1Z"
        transform="translate(-293.6 504.996)"
        fill="#5e6eff"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconDownload'
};
</script>

<style lang="postcss"></style>
