module.exports = {
  theme: {
    extend: {
      translate: {
        'curve-compensation': '50px'
      },
      boxShadow: {
        standard: '0 3px 6px rgba(0,0,0,0.15)'
      },
      spacing: {
        offset: '400px',
        14: '3.5rem' // Fits into default scale
      },
      maxWidth: {
        mobile: '90%',
        max: '90%'
      },
      minWidth: {
        card: '500px'
      },
      width: {
        '9/10': '90%',
        '1/5': '20%',
        '2/5': '40%',
        '3/5': '60%',
        '4/5': '80%',
        '5/5': '100%'
      },
      screens: {
        screen1440: '1400px',
        xxl: '1900px'
      },
      colors: {
        brand: {
          blue: '#7E85FF',
          darkerBlue: '#5E6EFF',
          lighterBlue: '#B8BEFF',
          green: '#007853',
          lightGreen: '#2DC84D',
          red: '#FF5C35',
          white: '#FFFFFF',
          black: '#000000',
          grey: '#F7F4F4',
          lightGrey: '#F7F4F4',
          cream: '#FAF8F4',
          'dark-grey': '#B2B2B2',
          border: '#DEDBDB',
          'light-blue': '#BAC0FF',
          'super-light-blue': '#D1D4F7',
          'chart-blue': '#B9BFFA',
          pink: '#FFAFC7',
          waveWhite: '#FFFFFF',
          waveBlue: '#5E6EFF',
          waveLightBlue: '#BAC0FF',
          waveRed: '#FF5C35',
          wavePink: '#FFAFC7',
          waveDarkGreen: '#007853',
          waveLightGreen: '#2DC84D'
        }
      },
      fontFamily: {
        'ginto-black': ['GintoNormal-Black', 'sans-serif'],
        'ginto-black-italic': ['GintoNormal-BlackItalic', 'sans-serif'],
        'ginto-bold': ['GintoNormal-Bold', 'sans-serif'],
        'ginto-bold-italic': ['GintoNormal-BoldItalic', 'sans-serif'],
        'ginto-light': ['GintoNormal-Light', 'sans-serif'],
        'ginto-light-italic': ['GintoNormal-LightItalic', 'sans-serif'],
        'ginto-medium': ['GintoNormal-Medium', 'sans-serif'],
        'ginto-regular': ['GintoNormal-Regular', 'sans-serif'],
        'ginto-regular-italic': ['GintoNormal-RegularItalic', 'sans-serif'],
        'ginto-thin': ['GintoNormal-Thin', 'sans-serif'],
        'ginto-thin-italic': ['GintoNormal-ThinItalic', 'sans-serif']
      },
      fontSize: {
        // desktop
        xl: '8.4rem',
        large: '7.2rem',
        medium: '2.6rem',
        body: '1.8rem',
        // brand font sizes specifically from the XD document
        'brand-09': '0.9rem',
        'brand-10': '1rem',
        'brand-12': '1.2rem',
        'brand-14': '1.4rem',
        'brand-16': '1.6rem',
        'brand-18': '1.8rem',
        'brand-19': '1.9rem',
        'brand-26': '2.6rem',
        'brand-28': '2.8rem',
        'brand-32': '3.2rem',
        'brand-40': '4rem',
        'brand-56': '5.6rem',
        'brand-80': '8rem',
        // mobile
        'mobile-large': '3.4rem',
        'mobile-medium': '2rem',
        'mobile-regular': '1.4rem',
        'mobile-body': '1.1rem',
        // final type sizes as of 14 April 2020
        // consolidated by Nick Smith
        // > 640px
        'brand-header': '8rem',
        'brand-sub-header': '5.6rem',
        'brand-title': '4rem',
        'brand-sub-title': '3.2rem',
        'brand-body-large': '2.6rem',
        'brand-body-medium': '2rem',
        'brand-body': '1.6rem',
        // < 640px (mobile)
        'brand-mobile-header': '3.8rem',
        'brand-mobile-sub-header': '2.8rem',
        'brand-mobile-title': '2rem',
        'brand-mobile-body-large': '1.6rem',
        'brand-mobile-body-medium': '1.4rem',
        'brand-mobile-body': '1.2rem',
        'brand-mobile-small': '0.8rem'
      }
    }
  },
  purge: false,
  variants: {},
  plugins: []
};
