<template>
  <div class="video-player" @click="toggleVideo">
    <div class="video-player__button" v-if="!isPlaying">
      <IconPlay :width="50" />
    </div>
    <video ref="video" :poster="poster" loop :class="{ playing: isPlaying }">
      <source :src="video.source.url" :type="video.source.type" />
      <p>
        Your browser doesn't support HTML5 video. Here is a
        <a :href="video.source.url">link to the video</a>
        instead.
      </p>
    </video>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import IconPlay from '@/components/Icon/IconPlay';

export default {
  name: 'VideoPlayer',
  components: {
    IconPlay
  },
  props: {
    source: String,
    poster: {
      type: String,
      default: '@/assets/images/homepage-video-poster.jpg'
    },
    type: {
      type: String,
      default: 'video/mp4'
    },
    video: [Array, Object]
  },
  data: () => {
    return {
      isPlaying: false
    };
  },
  computed: {
    ...mapGetters(['getIsMobile'])
  },
  methods: {
    toggleVideo() {
      const video = this.$refs.video;
      if (video.paused) {
        video.play();
        video.muted = false;
      } else {
        video.pause();
        video.muted = true;
      }
      this.isPlaying = !this.isPlaying;
    }
  }
};
</script>

<style lang="postcss" scoped>
.video-player {
  @apply relative;
  @apply cursor-pointer;
  &__button {
    @apply absolute top-0 left-0;
    @apply w-full h-full;
    @apply flex justify-center items-center;
    @apply z-10;
    @apply pointer-events-none;
    svg {
      width: 50px;
      @screen sm {
        width: 100px;
      }
      @screen md {
        width: 100px;
      }
      @screen xxl {
        width: 200px;
      }
    }
  }
  video {
    transition-property: all;
    transition-duration: 1000ms;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    filter: brightness(50%);
    &.playing {
      filter: brightness(100%);
    }
    @apply object-cover;
    @screen sm {
      width: 70vw;
    }
    &:hover {
      filter: brightness(100%);
    }
  }
}
</style>
