<template>
  <div class="filter-item" :class="{ active: active }">
    <div class="filter-item__container">
      <div class="filter-item__label" @click="active = !active">
        <button>
          <span v-html="!selected ? label : selected.label" v-if="label">{{
            selected
          }}</span>
          <span v-else>Loading...</span>
          <div class="filter-item__icon">
            <IconArrow direction="down" />
          </div>
        </button>
      </div>
      <ul class="filter-item__options" :class="{ active: active }" v-if="items">
        <li @click="applyFilter({ label: 'All', slug: undefined })">All</li>
        <li
          v-for="(item, index) in items"
          :key="`${label}-item-${index}`"
          @click="applyFilter(item)"
        >
          {{ item.label }}
        </li>
      </ul>
      <ul class="filter-item__options" :class="{ active: active }" v-else>
        <li>Loading items...</li>
      </ul>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/components/Icon/IconArrow';

export default {
  name: 'FilterItem',
  components: {
    IconArrow
  },
  props: {
    label: {
      type: String,
      default: null
    },
    items: {
      type: [Array, Object],
      default: null
    }
  },
  data() {
    return {
      active: false,
      selected: null
    };
  },
  methods: {
    applyFilter(item) {
      if (this.label === 'Location') {
        this.$store.commit('setFilterLocation', item.slug);
      }
      if (this.label === 'Category') {
        this.$store.commit('setFilterCategory', item.slug);
      }
      this.selected = item;
      this.active = false;
    }
  }
};
</script>

<style lang="postcss" scoped>
.filter-item {
  @apply w-full max-w-2xl;
  font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
  @apply cursor-pointer;
  &__container {
    @apply relative;
  }
  &__label {
    @apply flex items-center justify-between;
  }
  &.active {
    button {
      border-radius: 4px 4px 0px 0px;
    }
    .icon-arrow {
      transform: rotate(-180deg);
    }
  }
  @screen sm {
    @apply border-r;
    border-color: rgba(255, 255, 255, 0.15);
    @apply px-12;
    @apply mb-0;
  }
  &:first-of-type {
    @screen sm {
      @apply pl-0;
    }
  }
  &:last-of-type {
    @apply mb-0;
    @screen sm {
      @apply border-r-0;
      @apply pr-0;
    }
  }
  button {
    @apply flex items-center justify-between;
    @apply w-full;
    border-radius: 4px;
    @apply border-solid border border-brand-white;
    @apply p-6;
    @apply appearance-none outline-none;
  }
  &__icon {
    &:before {
      content: '';
      @apply absolute top-0;
      transform: translateX(-24px);
      width: 1px;
      height: 100%;
      @apply bg-brand-white;
    }
  }
  .icon-arrow {
    transition-property: transform;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
  }
  &__options {
    @apply absolute;
    @apply w-full;
    @apply bg-brand-lightGreen;
    @apply border-brand-white border border-t-0;
    @apply z-50;
    border-radius: 0px 0px 4px 4px;
    @apply h-0;
    @apply opacity-0;
    transition-property: height, opacity;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    @apply pointer-events-none;
    &.active {
      @apply h-auto;
      @apply opacity-100;
      @apply pointer-events-auto;
    }
    li {
      @apply p-6;
      @apply border-b border-brand-white;
      transition-property: background;
      transition-duration: 250ms;
      transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
      &:hover {
        @apply bg-brand-green;
      }
      &:last-of-type {
        @apply border-b-0;
      }
    }
  }
}
</style>
