<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.5"
    height="1"
    viewBox="0 0 10.5 1"
  >
    <line
      x2="10.5"
      transform="translate(0 0.5)"
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width="1"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMinus'
};
</script>

<style></style>
