<template>
  <section
    class="block-hero"
    :class="[
      background,
      fullWidth ? 'full-width' : '',
      { 'thank-you-page': thankYouPage }
    ]"
  >
    <div class="block-hero__content" :class="[background ? background : '']">
      <div class="block-hero__content__container">
        <!-- <router-link to="/news/covid-19-response">
          <ModalButton v-if="block.options.showCovid19Message">
            See here for the Westminster Foundation’s <br />Covid-19 response
          </ModalButton>
        </router-link> -->
        <h4 v-if="block.options.caseStudy">Case study</h4>
        <IconThumbsUp v-if="thankYouPage" />
        <h1 v-if="block.heading" v-html="block.heading"></h1>
        <h3
          v-if="block.subHeading && !block.options.caseStudy"
          v-html="block.subHeading"
        ></h3>
        <div
          class="block-hero__content__container__body"
          v-if="block.body"
          v-html="block.body"
        ></div>
        <div
          class="block-hero__content__container__cta"
          v-for="(item, index) in block.ctaGroup"
          :key="`cta-${index}`"
        >
          <CtaItem :label="item.label" :link="`${item.link}`" />
        </div>
      </div>
      <div class="block-hero__content__curve" :class="[background]"></div>
    </div>
    <div class="block-hero__media">
      <img
        v-if="block.media.images.length"
        :src="images[0].src"
        :alt="images[0].title"
        :srcset="images[0].srcset"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { background } from '@/mixins';
import CtaItem from '@/components/Cta/CtaItem';
// import ModalButton from '@/components/Modal/ModalButton';
import IconThumbsUp from '@/components/Icon/IconThumbsUp';

export default {
  name: 'BlockHero',
  components: {
    CtaItem,
    // ModalButton,
    IconThumbsUp
  },
  props: {
    block: [Array, Object]
  },
  mixins: [background],
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getIsModalWindowOpen',
      'getIsMobilePanelOpen'
    ]),
    images() {
      return this.block.media.images;
    },
    fullWidth() {
      return this.block.options.fullWidth ? this.block.options.fullWidth : null;
    },
    thankYouPage() {
      return this.block.options.thankYouPage ? true : false;
    }
  },
  methods: {
    toggleModalWindow() {
      this.$store.commit('setIsModalWindowOpen', !this.getIsModalWindowOpen);
    }
  }
};
</script>

<style lang="postcss">
.block-hero {
  @apply bg-brand-white;
  @apply flex flex-col-reverse;
  @apply h-screen;
  @apply relative;
  @apply overflow-hidden;
  @screen sm {
    @apply flex-row;
  }
  &.bg-brand-grey,
  &.bg-brand-cream {
    .block-hero__content__container {
      h1,
      h4 {
        @apply text-brand-black;
        @screen sm {
          @apply text-brand-white;
        }
      }
    }
  }
  &__content {
    @apply flex flex-col justify-center items-center;
    height: 35vh;
    @apply relative;
    @screen sm {
      @apply static;
      @apply h-full;
      width: 40vw;
    }
    &__container {
      @apply z-40;
      @apply max-w-lg;
      @screen sm {
        @apply w-full;
        @apply max-w-none;
      }
      h4 {
        @apply text-brand-white text-center font-ginto-regular;
        font-size: calc(16px + (26 - 16) * (100vw - 640px) / (1920 - 640));
        @apply mb-8;
        @screen sm {
          @apply mb-0;
        }
      }
      h1 {
        font-size: calc(38px + (80 - 38) * (100vw - 640px) / (1920 - 640));
        @apply text-white text-center leading-tight font-ginto-black;
      }
      h3 {
        @apply mt-6;
        @apply text-white text-center;
        @apply max-w-4xl;
        @apply mx-auto;
        font-size: calc(16px + (16 - 16) * (100vw - 640px) / (1920 - 640));
        @screen sm {
          @apply mt-12;
        }
      }
      p {
        @apply text-white;
        font-size: calc(16px + (16 - 16) * (100vw - 640px) / (1920 - 640));
      }
      &__cta {
        @apply py-16;
        @apply w-full;
        @apply flex justify-center;
      }
      @screen sm {
        transform: translateX(50px);
      }
    }
    &__curve {
      @apply absolute top-0;
      @apply pointer-events-none;
      border-radius: 100%;
      height: 15vh;
      width: 115vw;
      z-index: 21;
      transform: translateY(-5vh);
      @screen sm {
        @apply z-20;
        height: 110%;
        width: 20vw;
        right: 50vw;
      }
      @screen xxl {
        width: 20vw;
        right: 50vw;
      }
    }
  }
  &__media {
    height: 65vh;
    @apply w-full;
    @apply z-20;
    @screen sm {
      @apply z-0;
      @apply h-full;
      width: 60vw;
    }
    img {
      @apply object-cover;
      @apply w-full h-full;
    }
  }
  &.full-width {
    .block-hero__content {
      @apply w-full;
      @screen sm {
        @apply absolute;
        @apply bg-transparent;
      }
      &__container {
        transform: translate(0);
        @apply z-40;
      }
      &__curve {
        @screen sm {
          @apply absolute;
          @apply pointer-events-none;
          border-radius: 100%;
          height: 30vh;
          width: 120%;
          @apply z-20;
          transform: translateX(10vw);
          right: 0;
          top: calc(90vh);
        }
      }
    }
    .block-hero__media {
      @apply w-full;
      height: 65vh;
      @screen sm {
        @apply h-full;
      }
    }
  }
  &.thank-you-page {
    .block-hero__content__container {
      @apply text-center;
      @screen sm {
        @apply max-w-4xl;
      }
      .thumbs-up {
        @apply mb-20;
        @apply flex justify-center;
      }
      h1 {
        font-size: calc(16px + (26 - 16) * (100vw - 640px) / (1920 - 640));
        @apply font-ginto-regular;
        @apply mb-20;
      }
      p {
        @apply mb-8;
      }
    }
  }
}
</style>
