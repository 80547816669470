<template>
  <div
    class="flex justify-center items-center h-full w-full overflow-hidden"
    ref="container"
    :class="{ active }"
  >
    <img
      class="h-12 absolute logotype"
      :src="
        require('@/assets/images/Westminster_Foundation_Website_Header_Logo_WF_Wordmark.svg')
      "
      :style="
        active ? `transform: translateY(${this.getHeightHeader - 1}px)` : ''
      "
    />
    <img
      class="h-14 absolute logo"
      :src="
        require('@/assets/images/Westminster_Foundation_Website_Header_Logo_WF_Logo.svg')
      "
      :style="
        !active ? `transform: translateY(${this.getHeightHeader + 1}px)` : ''
      "
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { throttle } from 'lodash';
import { bind, unbind } from '@/utils/eventListener';

export default {
  name: 'LogoNav',

  data: () => ({
    h: 0,
    active: false
  }),

  computed: {
    ...mapGetters(['getHeightHeader'])
  },

  mounted() {
    this.h = this.$refs.container.offsetHeight;
    this.throttledHandleScroll = throttle(this.handleScroll, 500, {
      leading: false,
      trailing: true
    });
    bind(window, 'scroll', this.throttledHandleScroll);
  },

  beforeDestroy() {
    unbind(window, 'scroll', this.throttledHandleScroll);
  },

  methods: {
    handleScroll() {
      this.active = !!window.scrollY;
    }
  }
};
</script>

<style scoped>
.logotype,
.logo {
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
}

.logotype {
  @apply opacity-100;
}

.logo {
  height: 50px;
  width: 50px;
  @apply opacity-0;
}

.active .logotype {
  @apply opacity-0;
}

.active .logo {
  transform: translateY(0px);
  @apply opacity-100;
}
</style>
