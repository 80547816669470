<template>
  <main ref="loader" :style="headerCompensation">
    <article class="news-entry" v-if="content">
      <div class="news-entry__cover">
        <img
          :src="content.cover.src"
          :alt="content.cover.alt"
          :srcset="content.cover.srcset"
        />
      </div>
      <div class="news-entry__meta">
        <h4 class="category">{{ content.category.title }}</h4>
        <h1 v-html="content.title"></h1>
        <span v-html="content.date"></span>
      </div>
      <div class="news-entry__group">
        <div
          v-for="(block, index) in content.group"
          :key="`news-entry__group__block-${index}`"
        >
          <div
            class="news-entry__group__block__text"
            v-html="block.content"
            v-if="block.type === 'text'"
          ></div>
          <div
            class="news-entry__group__block__image"
            v-if="block.type === 'image'"
          >
            <img
              :src="block.image.src"
              :alt="block.image.alt"
              :srcset="block.image.srcset"
            />
          </div>
          <div
            class="news-entry__group__block__quote"
            v-if="block.type === 'quote'"
          >
            <BlockQuote :block="blockQuoteObject(block)" />
          </div>
          <BlockEmbeddedVideo
            class="news-entry__group__block__video"
            v-if="block.type === 'video'"
            :url="block.url"
          />
        </div>
      </div>
    </article>
    <div class="news-more-stories" v-if="otherStories">
      <h4>More news stories</h4>
      <div class="news-more-stories__entries">
        <CardNews
          v-for="(item, index) in otherStories"
          :key="`other-stories-${index}`"
          :entry="item"
        />
      </div>
    </div>
  </main>
</template>

<script>
import { loader, headerCompensation } from '@/mixins';
import CardNews from '@/components/Card/CardNews';
import BlockQuote from '@/components/Block/BlockQuote';
import BlockEmbeddedVideo from '@/components/Block/BlockEmbeddedVideo';

export default {
  name: 'NewsEntry',
  components: {
    CardNews,
    BlockQuote,
    BlockEmbeddedVideo
  },
  mixins: [loader, headerCompensation],
  data: () => {
    return {
      content: null,
      newsEntries: null
    };
  },
  computed: {
    otherStories() {
      return this.newsEntries
        ? this.newsEntries.filter(item => item.slug != this.$route.params.slug)
        : null;
    }
  },
  methods: {
    getContent(to) {
      const slug = to ? to : this.$route.params.slug;
      this.$http
        .get(`api/v1/news/${slug}.json`)
        .then(response => {
          this.content = response.data.data[0];
        })
        .catch(error => console.error(error));
    },
    blockQuoteObject(block) {
      return {
        body: block.body,
        cite: block.cite
      };
    }
  },
  created() {
    this.$http
      .get(`api/v1/news.json`)
      .then(response => {
        this.newsEntries = response.data.data;
      })
      .catch(error => console.error(error));
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$router.hash) {
        const element = document.getElementById('reports');
        const top = element.offsetTop;
        window.scrollTo(0, top);
      }
    });
  }
};
</script>

<style lang="postcss">
main {
  @apply bg-brand-cream;
  .news-entry {
    @apply min-h-screen;
    @apply pt-16;
    @apply pb-32;
    @screen sm {
      @apply pt-24;
      @apply pb-48;
      @apply max-w-screen-lg mx-auto;
    }
    &__meta {
      @apply text-center;
      @apply py-12;
      @apply max-w-max;
      @apply mx-auto;
      @screen sm {
        @apply max-w-screen-sm;
      }
      h4.category {
        @apply mb-12;
        font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
      }
      h1 {
        @apply font-ginto-black;
        @apply mb-12;
        @apply leading-none;
        font-size: calc(20px + (40 - 20) * (100vw - 640px) / (1920 - 640));
      }
      span {
        font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
      }
    }
    &__group {
      &__block__text {
        @apply max-w-max;
        @apply mx-auto;
        @screen sm {
          @apply max-w-screen-sm;
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
          @apply font-ginto-black;
        }
        h2 {
          font-size: calc(20px + (32 - 20) * (100vw - 640px) / (1920 - 640));
          @apply mb-8;
          @screen sm {
            @apply mb-8;
          }
        }
        p {
          @apply mb-8;
          font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
          &:last-of-type {
            @apply mb-0;
          }
          a {
            @apply border-b border-brand-blue;
            @apply pb-1;
          }
        }
        strong {
          @apply font-ginto-black;
        }
        ul {
          @apply list-disc;
          font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
          @apply py-8;
          li {
            @apply mb-8;
            &:last-of-type {
              @apply mb-0;
            }
          }
        }
      }
      &__block__image {
        @apply py-16;
        @screen sm {
          @apply py-32;
        }
      }
      &__block__video {
        @apply py-16;
        @screen sm {
          @apply py-32;
        }
      }
      &__block__quote {
        blockquote {
          p {
            @apply mb-16;
            font-size: calc(16px + (28 - 16) * (100vw - 640px) / (1920 - 640));
            &:last-of-type {
              @apply mb-0;
            }
          }
        }
      }
    }
  }
  .news-more-stories {
    @apply bg-brand-white;
    @apply py-16;
    @screen sm {
      @apply py-32;
    }
    h4 {
      @apply font-ginto-black;
      @apply text-center;
      @apply mb-16;
      font-size: calc(20px + (40 - 20) * (100vw - 640px) / (1920 - 640));
      @screen sm {
        @apply mb-32;
      }
    }
    &__entries {
      @apply flex flex-wrap;
      @screen sm {
        @apply max-w-max mx-auto;
        @apply justify-between;
      }
    }
  }
}
</style>
