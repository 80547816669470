<template>
  <main ref="loader" class="case-study">
    <div v-if="blocks">
      <BlockWave
        v-bind="{
          enabled: true,
          position: {
            x: 25,
            y: -33
          },
          color: $colours['waveBlue'],
          rotate: 45,
          alignment: {
            x: 'left'
          }
        }"
      >
        <BlockWave
          v-bind="{
            enabled: true,
            position: {
              x: 150,
              y: 150
            },
            color: $colours['waveBlue'],
            rotate: 180,
            alignment: {
              x: 'right'
            }
          }"
          @mask="heroMask = $event"
        >
          <BlockHero :block="hero" />
        </BlockWave>
      </BlockWave>
      <BlockWave
        :enabled="true"
        :color="$colours['waveBlue']"
        :maskData="heroMask"
      >
        <BlockWave v-bind="waveApiToComponentCoupler(content.wave['1'])">
          <BlockWave v-bind="waveApiToComponentCoupler(content.wave['2'])">
            <div class="case-study__blocks">
              <div
                v-for="block in blocks"
                :key="block.id"
                :class="`block-${block.type.toLowerCase()}-wrapper`"
              >
                <BlockCard v-if="block.type === 'Card'" :block="block" />
                <BlockQuote v-if="block.type === 'Quote'" :block="block" />
              </div>
            </div>
          </BlockWave>
        </BlockWave>
      </BlockWave>
      <BlockWave
        v-bind="{
          enabled: true,
          type: 4,
          position: {
            x: 0,
            y: 0
          },
          color: $colours['waveLightBlue']
        }"
      >
        <BlockStats
          :block="content.statisticGroup"
          :prev="content.previousCaseStudy"
          :next="content.nextCaseStudy"
        />
      </BlockWave>
    </div>
  </main>
</template>

<script>
import { loader, maskBus } from '@/mixins';
import BlockWave, { fallback } from '@/components/Block/BlockWave';

import {
  BlockHero,
  BlockCard,
  BlockQuote,
  BlockStats
} from '@/components/Block';

export default {
  name: 'CaseStudy',
  components: {
    BlockHero,
    BlockCard,
    BlockQuote,
    BlockStats,
    BlockWave
  },
  data: () => {
    return {
      content: null,
      heroMask: fallback
    };
  },
  mixins: [loader, maskBus],
  computed: {
    hero() {
      return {
        heading: this.content ? this.content.title : null,
        media: this.content ? this.content.media : null,
        options: {
          backgroundColour: 'cream',
          fullWidth: true,
          caseStudy: true
        }
      };
    },
    blocks() {
      return this.content ? this.content.pageGroup : null;
    }
  },
  methods: {
    getContent(to) {
      const slug = to ? to : this.$route.params.slug;
      this.$http
        .get(`/api/v1/case-study/${slug}.json?i=1`)
        .then(response => {
          this.content = response.data.data[0];
        })
        .catch(error => console.error(error));
    }
  }
};
</script>

<style lang="postcss">
.case-study {
  &__blocks {
    @apply relative;
    /* @apply bg-brand-cream; */
    @apply z-40;
    @apply py-32;
    /* @apply z-20; */
    .block-card-wrapper,
    .block-card-quote {
      @screen sm {
        @apply py-32;
      }
      .block-card {
        .block-card__divider {
          @apply hidden;
        }
        h3 {
          @apply text-center;
          @screen sm {
            @apply text-left;
          }
        }
      }
    }
    .block-card-wrapper {
      .block-card__container {
        .block-card__content {
          .block-card__content__container {
            @apply ml-auto;
          }
        }
      }
    }
  }
}
</style>
