<template>
  <main :style="headerCompensation">
    <ReportsCore />
  </main>
</template>

<script>
import { loader, headerCompensation } from '@/mixins';
import ReportsCore from '@/components/Reports/ReportsCore';

export default {
  name: 'Resources',
  components: {
    ReportsCore
  },
  // used with Title Mixin
  title: 'Reports & Resources',
  data: () => {
    return {
      content: null,
      category: null
    };
  },
  mixins: [loader, headerCompensation],
  computed: {
    categories() {
      const list = this.content.map(item => item.category);
      return list.filter((item, index) => list.indexOf(item) === index);
    },
    filteredList() {
      return this.category
        ? this.content.filter(item => item.category.slug === this.category)
        : this.content;
    }
  },
  methods: {
    getContent() {
      this.$http
        .get(`api/v1/resources.json`)
        .then(response => {
          this.content = response.data.data;
        })
        .catch(error => console.error(error));
    },
    applyFilter(categorySlug) {
      this.category = categorySlug;
    },
    isActive(categorySlug) {
      return categorySlug === this.category ? 'active' : '';
    }
  }
};
</script>
