<template>
  <section class="block-embed" :class="[background]" v-if="block">
    <EmbedGrantCharts v-if="block.component === 'Embed/EmbedGrantCharts'" />
    <div
      class="block-embed__component"
      v-if="block.component === 'Embed/EmbedUKMap'"
    >
      <div class="block-embed__component__object">
        <EmbedUKMap />
      </div>
      <div class="block-embed__component__content" v-if="block.body">
        <div class="block-embed__component__content__container">
          <h2 v-if="block.heading" v-html="block.heading"></h2>
          <div
            class="block-embed__component__content__text"
            v-html="block.summary"
          ></div>
          <ButtonReadMore
            @click.native="expand = true"
            colour="white"
            v-if="!expand"
          />
          <div
            class="block-embed__component__content__text--expand"
            v-html="block.body"
            :class="{ show: expand }"
          ></div>
        </div>
      </div>
    </div>
    <div
      v-if="block.component === 'Embed/EmbedMaps'"
      class="block-embed__component block-embed__maps"
    >
      <div class="block-embed__component__content" v-if="block.body">
        <h2 v-if="block.heading" v-html="block.heading"></h2>
        <div
          class="block-embed__component__content__text"
          v-html="block.body"
        ></div>
      </div>
      <EmbedMaps />
      <div class="block-embed__curve" :class="[nextcolour]" />
    </div>
  </section>
</template>

<script>
import ButtonReadMore from '@/components/Button/ButtonReadMore';
import { background, nextcolour } from '@/mixins';
import EmbedGrantCharts from '@/components/Embed/EmbedGrantCharts';
import EmbedUKMap from '@/components/Embed/EmbedUKMap';
import EmbedMaps from '@/components/Embed/EmbedMaps';

export default {
  name: 'BlockEmbed',
  components: {
    ButtonReadMore,
    EmbedGrantCharts,
    EmbedUKMap,
    EmbedMaps
  },
  props: {
    block: [Array, Object]
  },
  data: () => {
    return {
      expand: false
    };
  },
  mixins: [background, nextcolour]
};
</script>

<style lang="postcss">
@import '../../styles/animate.css';

.block-embed {
  overflow: hidden;
  &__content {
    @apply flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  &__component {
    @apply min-h-screen;
    position: relative;
    @screen sm {
      @apply flex flex-wrap;
    }
    &__object {
      @screen sm {
        @apply w-1/2;
      }
    }
    &__content {
      @apply text-brand-white;
      @apply max-w-mobile mx-auto;
      @apply text-center;
      @apply py-16;
      @screen sm {
        @apply w-1/2;
        @apply flex flex-col justify-center;
      }
      &__container {
        @apply max-w-screen-sm;
        font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
        @screen sm {
          @apply pl-56;
        }
      }
      h2 {
        @apply font-ginto-black;
        @apply mb-8;
        font-size: calc(32px + (56 - 32) * (100vw - 640px) / (1920 - 640));
      }
      button {
        @apply outline-none appearance-none;
        @apply opacity-100;
        transition-duration: 250ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
        font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
      }
      &__text {
        p {
          @apply font-ginto-regular;
          @apply mb-8;
          font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
          strong {
            @apply font-ginto-bold;
          }
        }
        &--expand {
          @apply h-0;
          @apply overflow-hidden;
          @apply opacity-0;
          transition-duration: 500ms;
          transition-property: all;
          transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
          transform: translateY(20px);
          p {
            @apply mb-8;
            font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
          }
          &.show {
            transform: translateY(0px);
            @apply overflow-visible;
            @apply h-auto;
            @apply opacity-100;
          }
        }
      }
    }
  }

  &__maps {
    flex-direction: column;
    gap: 40px;
    padding-bottom: 10vh;
    @screen lg {
      padding-bottom: 30vh;
    }
  }

  &__curve {
    @apply absolute;
    @apply pointer-events-none;
    border-radius: 100%;
    bottom: 0;
    left: 0;
    height: 25vh;
    width: 200vw;
    transform: translate(-50vw, 20vh);
    @screen lg {
      height: 30vh;
      width: 114vw;
      transform: translate(-7vw, 20vh);
    }
  }
}
</style>
