<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 42.93 42.93"
  >
    <g id="Layer_1" data-name="Layer 1">
      <g id="Group_56" data-name="Group 56">
        <path
          fill="#637af5"
          d="M6.33,6.29a21.29,21.29,0,0,0-.18,30.12l.18.19a21.3,21.3,0,0,0,30.12.19l.19-.19a21.33,21.33,0,0,0,.09-30.16l-.12-.12A21.32,21.32,0,0,0,6.45,6.17Zm29.3,29.29a19.89,19.89,0,0,1-28.13.15l-.15-.15A19.89,19.89,0,0,1,7.2,7.45l.15-.15a19.84,19.84,0,0,1,28.07-.13l.17.17a19.85,19.85,0,0,1,.21,28.07Z"
        />
        <path
          fill="#637af5"
          d="M30.66,11.2l-9.19,9.18L12.29,11.2a.85.85,0,0,0-1.19-.09h0a.81.81,0,0,0,.08,1.13l0,0,9.18,9.18-9.18,9.18a.8.8,0,0,0-.14,1.13s0,0,0,0a.85.85,0,0,0,1.19-.06v0l9.18-9.18,9.19,9.18a.8.8,0,0,0,1.12.15l0,0a.84.84,0,0,0-.07-1.19h0l-9.19-9.18,9.19-9.18a.85.85,0,0,0,.08-1.19h0a.81.81,0,0,0-1.13.08A.05.05,0,0,0,30.66,11.2Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconCloseBlue'
};
</script>

<style lang="postcss" scoped></style>
