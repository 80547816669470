<template>
  <button class="button-read-more">
    <div>
      <span>Read more</span>
      <IconPlus :colour="colour" />
    </div>
  </button>
</template>

<script>
import IconPlus from '@/components/Icon/IconPlus';

export default {
  name: 'ButtonReadMore',
  components: {
    IconPlus
  },
  props: {
    colour: String
  }
};
</script>

<style>
.button-read-more {
  @apply py-8;
  @apply flex;
  @apply mx-auto;
  div {
    @apply flex items-center;
    span {
      @apply font-ginto-bold;
      @apply mr-4;
      font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
    }
  }
  @apply appearance-none outline-none;
}
</style>
