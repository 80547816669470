<template>
  <section class="block-list" :class="[background]">
    <h2 class="z-40" v-if="block.heading" v-html="block.heading"></h2>
    <h3 class="z-40" v-if="block.subHeading" v-html="block.subHeading"></h3>
    <div class="z-40 block-list__group">
      <div
        class="block-list__group__list"
        v-for="(group, index) in block.listGroup"
        :key="`list-group-${index}`"
      >
        <div class="block-list__group__list__items">
          <h3 v-if="group.heading" v-html="group.heading"></h3>
          <div
            class="block-list__group__list__items__item"
            v-for="(listitem, index) in group.items"
            :key="`list-item-${index}`"
          >
            <div class="block-list__group__list__items__item__icon">
              <img :src="group.icon" />
            </div>
            <div class="block-list__group__list__items__item__content">
              <p v-if="listitem.body" v-html="listitem.body"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { background } from '@/mixins';

export default {
  name: 'BlockList',
  props: {
    block: [Array, Object]
  },
  mixins: [background]
};
</script>

<style lang="postcss">
.block-list {
  @apply flex flex-wrap;
  @apply py-16;
  @screen sm {
    @apply items-center flex-col justify-center;
    @apply py-32;
    @apply min-h-screen;
  }
  &.bg-brand-green {
    @apply text-brand-white;
  }
  &.bg-brand-grey {
    @apply text-brand-black;
  }
  h2 {
    @apply font-ginto-black;
    @apply mb-16;
    @apply mx-auto;
    font-size: calc(28px + (56 - 28) * (100vw - 640px) / (1920 - 640));
  }
  h3 {
    @apply text-center;
    @apply max-w-md;
    @apply mb-16;
    @apply mx-auto;
    font-size: calc(12px + (26 - 12) * (100vw - 640px) / (1920 - 640));
    @screen sm {
      @apply mb-16;
      @apply max-w-screen-lg;
    }
  }
  &__group {
    @screen sm {
      @apply flex;
    }
    &__list {
      @apply flex flex-col;
      @apply w-full;
      &:first-of-type {
        .block-list__group__list__items {
          @screen sm {
            @apply pr-16;
          }
        }
        .block-list__group__list__items__item {
          @screen sm {
            @apply justify-end;
          }
        }
      }
      &:last-of-type {
        .block-list__group__list__items {
          @screen sm {
            @apply pl-16;
          }
        }
        .block-list__group__list__items__item {
          @screen sm {
            @apply justify-start;
          }
        }
      }
      @screen sm {
        @apply border-r border-solid border-brand-white;
        @apply max-w-screen-md;
      }
      &:last-of-type {
        @apply border-r-0;
      }
      &__items {
        @apply pb-16;
        @screen sm {
          @apply pb-0;
        }
        h3 {
          @apply text-white font-ginto-black text-center;
          font-size: calc(28px + (40 - 28) * (100vw - 640px) / (1920 - 640));
          @apply pb-8;
          @apply text-medium;
          @screen sm {
            @apply pb-4;
          }
        }
        &__item {
          @apply flex items-center;
          @apply mb-6;
          @apply max-w-max mx-auto;
          @screen sm {
            @apply max-w-none mx-0;
            @apply my-6;
            @apply pb-6;
          }
          &:last-of-type {
            @apply pb-0 mb-0;
          }
          &__icon {
            /* width: 26px; */
            @apply mr-8;
            @screen sm {
              /* @apply mr-0; */
              /* @apply w-1/6; */
            }
          }
          &__content {
            @apply w-3/4;
            @apply font-ginto-regular;
            font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
          }
        }
      }
    }
  }
}
</style>
