export const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);
export const isUndefined = value => value === void 0;
export const isDefined = value => !isUndefined(value) && !isNil(value);
export const isNil = value => value === null;
export const isString = value => typeof value === 'string';
export const isNumber = value => typeof value === 'number';
export const isFunction = value =>
  typeof value === 'function' || value instanceof Function;
export const isObject = value => {
  const type = typeof value;
  return (
    !isNil(value) &&
    (type === 'object' || type === 'function') &&
    !Array.isArray(value)
  );
};
export const isEmptyObject = value =>
  isObject(value) && Object.entries(value).length === 0;
export const isArray = value => Array.isArray(value);
