import { fallback } from '@/components/Block/BlockWave';

export default {
  data: () => ({
    masks: new Map([]),
    maskChangeTracker: 0
  }),

  watch: {
    maskChangeTracker() {
      // maybe this can be throttled but ensure correct handling of last UUID's
      this.$forceUpdate();
    }
  },

  methods: {
    /**
     * Set a mask on the instance
     * @param {int || string} uuid
     * @param {object} mask
     */
    setMask(uuid, mask) {
      this.masks.set(uuid, mask);
      this.maskChangeTracker += 1;
    },

    /**
     * Get mask by UUID
     * @param {int || string} uuid
     */
    getMask(uuid) {
      if (this.masks.has(uuid)) return this.masks.get(uuid);
      return fallback;
    },

    /**
     * an easy v-bind helper to set props on a BlockWave
     * @param {object} block
     */
    setWave(block, waveNumber, maskUuid) {
      // if (!block.wave) return;
      let { update } = this.getMask(maskUuid);

      if (typeof update === 'undefined') update = 0;

      const { waveBecomeOverflow } = block.wave[waveNumber];

      return {
        ...this.waveApiToComponentCoupler(block.wave[waveNumber]),
        ...(waveBecomeOverflow && { maskData: this.getMask(maskUuid) }),
        update
      };
    },

    waveApiToComponentCoupler({
      waveEnable: enabled,
      waveType,
      waveColour,
      wavePosition,
      waveMobilePosition,
      waveAlignment,
      waveRotation,
      waveCropMode,
      waveAnimationDuration,
      waveReverseAnimation: reverse,
      waveOverrideOverflowHeight: overrideOverflowHeight
    }) {
      return {
        enabled,
        ...(waveType && { type: Number(waveType) }),
        ...(waveColour && { color: this.$colours[waveColour] }),
        ...(wavePosition && {
          position: {
            x: Number(wavePosition[0].x),
            y: Number(wavePosition[0].y)
          }
        }),
        ...(waveMobilePosition && {
          mobilePosition: {
            x: Number(waveMobilePosition[0].x),
            y: Number(waveMobilePosition[0].y)
          }
        }),
        alignment: {
          ...(waveAlignment.x && { x: waveAlignment.x }),
          ...(waveAlignment.y && { y: waveAlignment.y })
        },
        ...(waveRotation && { rotate: Number(waveRotation) }),
        cropMode: waveCropMode,
        ...(waveAnimationDuration && {
          duration: Number(waveAnimationDuration)
        }),
        reverse,
        overrideOverflowHeight
      };
    },

    update(uuid = null) {
      if (uuid) {
        const mask = this.getMask(uuid);
        this.setMask({ ...mask, update: mask.update + 1 });
        return;
      }

      // Update all
      for (const [uuid, mask] of this.masks) {
        let update = 1;
        if ('update' in mask) update += 1;

        this.masks.set(uuid, { ...mask, update });
      }
      this.maskChangeTracker += 1;
    }
  }
};
