<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__left">
        <div class="footer__brand">
          <div class="footer__brand__logo-square">
            <img
              src="@/assets/images/westminster-foundation-logo-square.svg"
              alt="Westminster Foundation Logo"
            />
          </div>
          <div class="footer__brand__logo-long">
            <img
              src="@/assets/images/westminster-foundation-logo-long.svg"
              alt="Westminster Foundation Logo"
            />
          </div>
          <span>© Westminster Foundation 1974–{{ getCurrentYear }}</span>
        </div>
      </div>
      <div class="footer__right">
        <div class="footer__columns">
          <div class="footer__contact">
            <div v-if="false">
              <a href="mailto:westmister.foundation@grosvenor.com"
                >westmister.foundation@grosvenor.com</a
              >
              <a href="tel:+4402074080988">+44 (0)20 7408 0988</a>
            </div>
            <router-link to="/contact">Contact</router-link>
            <router-link to="/grants">How to apply</router-link>
            <!-- <router-link to="/news#resources">Resources</router-link> -->
            <a href="https://adwf.org.uk/" target="_blank"
              >Anne Duchess of Westminster’s Fund</a
            >
          </div>
          <div class="footer__navigation">
            <nav>
              <router-link to="/cookie-policy">Cookie policy</router-link>
              <router-link to="/privacy-policy">Privacy policy</router-link>
            </nav>
            <span class="footer__credits">Registered charity No. 267618</span>
          </div>
        </div>
        <div class="footer__columns">
          <CoreLinks class="footer__links" />
        </div>
        <div class="footer__subscribe" v-if="false">
          <h5>Stay up to date — sign up for our eNewsletter:</h5>
          <div
            class="footer__subscribe__input"
            :class="[focusSubscribe ? 'opacity-100' : 'opacity-25']"
          >
            <input
              type="text"
              placeholder="Email address"
              @focus="focusSubscribe = true"
              @blur="focusSubscribe = false"
            />
            <IconArrow width="21" />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import IconArrow from '@/components/Icon/IconArrow';
import CoreLinks from '@/components/Core/CoreLinks';

export default {
  name: 'Footer',
  components: {
    IconArrow,
    CoreLinks
  },
  data: function() {
    return {
      focusSubscribe: false
    };
  },
  computed: {
    getCurrentYear() {
      const date = new Date();
      const year = date.getFullYear();
      return year;
    }
  }
};
</script>

<style lang="postcss" scoped>
.footer {
  @apply bg-brand-darkerBlue;
  @apply text-brand-white;
  @apply py-16;
  font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
  @apply border-t border-brand-grey;
  .container {
    @apply max-w-max mx-auto;
    @apply flex flex-wrap flex-col-reverse;
    @screen sm {
      @apply flex-row items-start justify-between;
    }
  }
  h5 {
    @apply font-ginto-regular;
    font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
  }
  &__left {
    @apply w-full;
    @screen sm {
      @apply w-1/2;
      @apply flex-grow-0;
    }
  }
  &__right {
    @apply flex flex-col justify-end;
    @apply mb-8;
    @screen sm {
      @apply w-1/2;
      @apply flex-grow-0;
    }
  }
  &__columns {
    @apply w-full;
    @apply flex;
    @apply gap-8;
  }
  &__links {
    @apply w-full;
    @apply flex justify-center;
    @apply mt-8;
    @apply gap-8;
    @screen sm {
      @apply justify-start;
    }
  }
  &__credits {
    @apply block;
    @apply w-full;
    @apply flex;
    min-height: 2.4rem;
  }
  &__brand {
    @apply w-full;
    @apply flex justify-center;
    &__logo {
      &-square {
        @screen sm {
          @apply hidden;
        }
      }
      &-long {
        @apply hidden;
        @screen sm {
          @apply block;
        }
      }
    }
    span {
      @apply hidden;
      @screen sm {
        @apply block;
        @apply mt-8;
        @apply text-brand-blue;
      }
    }
    @screen sm {
      @apply justify-start flex-col;
    }
  }
  &__contact {
    @apply w-full;
    @screen sm {
      @apply w-1/2;
    }
    a {
      @apply block;
      min-height: 2.4rem;
    }
  }
  &__navigation {
    @apply w-full;
    @screen sm {
      @apply w-1/2;
    }
    nav {
      a {
        @apply block;
        min-height: 2.4rem;
      }
    }
  }
  &__subscribe {
    @apply mt-8;
    @apply w-full;
    @apply flex flex-col justify-between;
    &__input {
      @apply flex items-center;
      transition-duration: 250ms;
      transition-property: opacity;
      transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
      @apply border-b border-white;
    }
    input {
      @apply outline-none appearance-none bg-transparent;
      @apply w-full;
      @apply py-4;
      &::placeholder {
        color: white;
      }
    }
    @screen sm {
      @apply mt-0;
      @apply w-1/3;
    }
  }
}
</style>
