<template>
  <div class="icon-arrow">
    <div v-if="direction === 'prev'" class="arrow-prev">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45.342"
        height="5.822"
        viewBox="0 0 45.342 5.822"
      >
        <path
          d="M258.791,5162.32l4.39-2.911v2.412h40.952v1H263.181v2.411Z"
          transform="translate(-258.791 -5159.409)"
          :fill="colour"
        />
      </svg>
    </div>
    <div v-else-if="direction === 'next'" class="arrow-next">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="arrowWidth"
        height="5.822"
        viewBox="0 0 45.342 5.822"
      >
        <path
          d="M304.133,5162.32l-4.39-2.911v2.412H258.791v1h40.952v2.41Z"
          transform="translate(-258.791 -5159.409)"
          :fill="colour"
        />
      </svg>
    </div>
    <div v-else-if="direction === 'down'" class="arrow-down">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="5.096"
        height="27.298"
        viewBox="0 0 5.096 27.298"
      >
        <path
          d="M27.3,2.548,22.908,0V2.111H0v.875H22.908V5.1Z"
          transform="translate(5.096) rotate(90)"
          :fill="colour"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'IconArrow',
  props: {
    colour: {
      type: String,
      default: '#ffffff'
    },
    width: {
      type: String,
      default: '45.342'
    },
    direction: {
      type: String,
      default: 'next'
    }
  },
  computed: {
    ...mapGetters(['getIsMobile']),
    arrowWidth() {
      return this.isMobile ? '32' : this.width;
    }
  }
};
</script>

<style lang="postcss"></style>
