<template>
  <section class="block-stats">
    <div class="block-stats__group">
      <div
        class="block-stats__group__item"
        v-for="(item, index) in block"
        :key="`block-stats-${index}`"
      >
        <span>{{ item.number }}</span>
        <p>{{ item.text }}</p>
      </div>
    </div>
    <div class="block-stats__cta">
      <div class="" v-if="prev !== ''">
        <CtaItem
          label="Previous"
          colour="black"
          :link="prev"
          direction="prev"
        />
      </div>
      <div v-if="next !== ''">
        <CtaItem label="Next" colour="black" :link="next" direction="next" />
      </div>
    </div>
  </section>
</template>

<script>
import CtaItem from '@/components/Cta/CtaItem';

export default {
  name: 'BlockStats',
  components: {
    CtaItem
  },
  props: {
    block: [Array, Object],
    next: {
      type: String
    },
    prev: {
      type: String
    }
  }
};
</script>

<style lang="postcss">
.block-stats {
  @apply flex flex-col;
  @apply py-16;
  @apply bg-brand-white;
  @screen sm {
    @apply py-48;
  }
  &__group {
    @apply z-30;
    @apply flex flex-col justify-center items-center;
    @apply text-brand-darkerBlue;
    @screen sm {
      @apply flex-row items-start;
    }
    &__item {
      @apply pb-8;
      @apply text-center;
      @apply max-w-sm;
      @screen sm {
        @apply pb-16;
        @apply max-w-3xl;
        @apply px-16;
      }
      span {
        @apply font-ginto-black;
        @apply mb-4;
        @apply inline-block;
        font-size: calc(28px + (80 - 28) * (100vw - 640px) / (1920 - 640));
      }
      p {
        @apply font-ginto-regular;
        font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
      }
    }
  }
  &__cta {
    @apply flex;
    @apply pt-16;
    @apply max-w-screen-sm mx-auto;
    z-index: 99;
    .cta-item {
      font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
      @apply mx-8;
    }
  }
}
</style>
