export default {
  computed: {
    background() {
      const backgroundColour = this.block.options.backgroundColour
        ? this.block.options.backgroundColour
        : 'darkerBlue';
      return `bg-brand-${backgroundColour}`;
    }
  }
};
