<template>
  <section id="reports" class="resource-core">
    <div class="resource-core__container">
      <h3>Reports & Resources</h3>
      <div class="resource-core__group">
        <div
          class="resource-core__group__item"
          v-for="(item, index) in content"
          :key="index"
        >
          <a :href="item.url ? item.url : '#'" download>
            <img
              :src="item.image.src"
              :srcset="item.image.srcset"
              alt="item.title"
            />
            <h4 v-html="item.title"></h4>
            <div class="resource-core__group__item__download">
              <span>Download</span>
              <IconDownload />
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IconDownload from '@/components/Icon/IconDownload';
export default {
  name: 'ReportsCore',
  components: {
    IconDownload
  },
  data: () => {
    return {
      content: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getContent();
    });
  },
  methods: {
    getContent() {
      this.$http
        .get(`api/v1/resources.json`)
        .then(response => {
          this.content = response.data.data;
        })
        .catch(error => console.error(error));
    }
  }
};
</script>

<style lang="postcss">
.resource-core {
  @apply bg-brand-white;
  @apply py-16;
  @screen sm {
    @apply py-32;
  }
  &__container {
    @apply max-w-max mx-auto;
  }
  h3 {
    @apply font-ginto-black;
    @apply text-center;
    @apply pb-16;
    @screen sm {
      @apply pb-32;
    }
    font-size: calc(28px + (40 - 28) * (100vw - 640px) / (1920 - 640));
  }
  &__group {
    @apply flex flex-wrap justify-between;
    &__item {
      width: calc(50% - 1rem);
      @apply cursor-pointer;
      @screen sm {
        width: calc(20% - 4rem);
      }
      img {
        transition-duration: 600ms;
        transition-property: transform, box-shadow;
        transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
        border-radius: 6px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
        transform: translateY(0px);
      }
      h4 {
        @apply mt-12;
        @apply py-6;
        @apply border-t border-brand-black;
        font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
        @apply font-ginto-black;
      }
      &__download {
        transition-duration: 600ms;
        transition-property: background;
        transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
        @apply flex justify-between items-baseline;
        @apply border-t border-brand-darkerBlue;
        @apply py-6;
        @apply text-brand-darkerBlue;
        font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
        @apply overflow-hidden;
        height: 40px;
        span {
          transition-duration: 600ms;
          transition-property: all;
          transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
          @apply pl-0;
        }
        svg {
          @apply overflow-hidden;
          transition-duration: 600ms;
          transition-property: all;
          transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
        }
      }
      &:hover {
        img {
          transform: translateY(-10px);
          box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
</style>
