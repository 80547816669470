<template>
  <div
    class="cta-item"
    :class="[
      bubble ? 'bubble' : '',
      disabled ? 'disabled' : '',
      position == 'center' ? 'justify-center' : ''
    ]"
  >
    <router-link
      v-if="link.indexOf('http') !== 0 && !disableLink"
      :to="link"
      :class="[textColour, disabled ? 'pointer-events-none disabled' : '']"
    >
      <IconArrow
        :colour="arrowColour"
        v-if="!disableIcon && !disabled && direction === 'prev'"
        :direction="direction"
      />
      <span>{{ label }}</span>
      <IconArrow
        :colour="arrowColour"
        v-if="!disableIcon && !disabled && direction === 'next'"
        :direction="direction"
      />
    </router-link>
    <a
      v-else-if="link.indexOf('http') === 0 && !disableLink"
      :href="link"
      target="_blank"
      :class="[textColour, disabled ? 'pointer-events-none disabled' : '']"
    >
      <IconArrow
        :colour="arrowColour"
        v-if="!disableIcon && !disabled && direction === 'prev'"
        :direction="direction"
      />
      <span>{{ label }}</span>
      <IconArrow
        :colour="arrowColour"
        v-if="!disableIcon && !disabled && direction === 'next'"
        :direction="direction"
      />
    </a>
    <div v-else-if="disableLink" class="cta-item__container">
      <IconArrow
        :colour="arrowColour"
        v-if="!disableIcon && !disabled && direction === 'prev'"
        :direction="direction"
      />
      <span>{{ label }}</span>
      <IconArrow
        :colour="arrowColour"
        v-if="!disableIcon && !disabled && direction === 'next'"
        :direction="direction"
      />
    </div>
  </div>
</template>

<script>
import IconArrow from '@/components/Icon/IconArrow';

export default {
  name: 'CtaItem',
  components: {
    IconArrow
  },
  props: {
    label: {
      type: String,
      default: 'More Information'
    },
    link: {
      type: String,
      default: '#'
    },
    colour: {
      type: String,
      default: 'white'
    },
    bubble: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'left'
    },
    disableIcon: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'next'
    },
    disableLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    textColour() {
      return `text-brand-${this.colour}`;
    },
    arrowColour() {
      let colour = '';
      for (let [key, value] of Object.entries(this.$colours)) {
        if (key === this.colour) colour = value;
      }
      return colour;
    }
  }
};
</script>

<style lang="postcss">
.cta-item {
  .cta-item__container,
  a {
    @apply font-ginto-regular;
    @apply inline-block;
    @apply flex items-center;
    font-size: calc(16px + (20 - 16) * (100vw - 640px) / (1920 - 640));
    &.disabled {
      @apply mr-0;
    }
    .icon-arrow {
      @apply mt-2;

      .arrow-prev {
        @apply mr-8;
      }
      .arrow-next {
        @apply ml-8;
        transition-duration: 500ms;
        transition-property: transform;
        transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
      }
    }
  }
  img {
    @apply inline-block;
    width: 32px;
    @screen sm {
      @apply w-auto;
    }
  }
  &.bubble {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    @apply py-8 px-16;
    @apply bg-brand-white;
    border-radius: 8px;
    @apply border-brand-black border;
  }
  &.disabled {
    @apply cursor-not-allowed;
    @apply bg-brand-grey;
  }
  &:hover {
    .arrow-next {
      transform: translateX(1.25rem);
    }
  }
}
</style>
