import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // holding page
    isOpen: false,
    activeSide: '',
    isMobile: innerWidth < 1024,
    isMobileMenuOpen: false,
    heightMobileMenu: 0,
    isMobilePanelOpen: false,
    isModalWindowOpen: false,
    // main website
    isMenuOpen: false,
    heightHeader: 0,
    isSubMenuOpen: false,
    isContentLoaded: false,
    filterLocation: undefined,
    filterCaseStudy: undefined,
    filterCategory: undefined,
    isFilterListLoaded: true
  },
  getters: {
    getIsFilterListLoaded: state => {
      return state.isFilterListLoaded;
    },
    getFilterCaseStudy: state => {
      return state.filterCaseStudy;
    },
    getFilterLocation: state => {
      return state.filterLocation;
    },
    getFilterCategory: state => {
      return state.filterCategory;
    },
    getIsContentLoaded: state => {
      return state.isContentLoaded;
    },
    getIsMenuOpen: state => {
      return state.isMenuOpen;
    },
    getHeightHeader: state => {
      return state.heightHeader;
    },
    getIsSubMenuOpen: state => {
      return state.isSubMenuOpen;
    },
    getIsOpen: state => {
      return state.isOpen;
    },
    getActiveSide: state => {
      return state.activeSide;
    },
    getIsMobile: state => {
      return state.isMobile;
    },
    getIsMobileMenuOpen: state => {
      return state.isMobileMenuOpen;
    },
    getHeightMobileMenu: state => {
      return state.heightMobileMenu;
    },
    getIsMobilePanelOpen: state => {
      return state.isMobilePanelOpen;
    },
    getIsModalWindowOpen: state => {
      return state.isModalWindowOpen;
    }
  },
  mutations: {
    setIsFilterListLoaded(state, payload) {
      state.isFilterListLoaded = payload;
    },
    setFilterCaseStudy(state, payload) {
      state.filterCaseStudy = payload;
    },
    setFilterCategory(state, payload) {
      console.log('setFilterCategory', payload);
      state.filterCategory = payload;
    },
    setFilterLocation(state, payload) {
      state.filterLocation = payload;
    },
    setIsContentLoaded(state, payload) {
      state.isContentLoaded = payload;
    },
    setIsMenuOpen(state, payload) {
      state.isMenuOpen = payload;
    },
    setHeightHeader(state, payload) {
      state.heightHeader = payload;
    },
    setIsSubMenuOpen(state, payload) {
      state.isSubMenuOpen = payload;
    },
    setIsOpen(state, payload) {
      state.isOpen = payload;
    },
    setActiveSide(state, payload) {
      state.activeSide = payload;
    },
    setIsMobile(state, payload) {
      state.isMobile = payload;
    },
    setIsMobileMenuOpen(state, payload) {
      state.isMobileMenuOpen = payload;
    },
    setHeightMobileMenu(state, payload) {
      state.heightMobileMenu = payload;
    },
    setIsMobilePanelOpen(state, payload) {
      state.isMobilePanelOpen = payload;
    },
    setIsModalWindowOpen(state, payload) {
      state.isModalWindowOpen = payload;
    }
  },
  actions: {},
  modules: {}
});
