<template>
  <div class="card-item">
    <div class="card-item__top">
      <div class="card-item__top__label-top" v-if="entry.hasCaseStudy">
        Case study
      </div>
      <div class="card-item__top__image-container">
        <img
          :src="entry.cover[0].placeholder"
          :alt="entry.cover[0].alt"
          :srcset="entry.cover[0].srcset"
        />
      </div>
      <div
        class="card-item__top__label-bottom"
        :class="colourLocation"
        v-html="entry.location.label"
      ></div>
    </div>
    <div class="card-item__bottom">
      <div class="card-item__bottom__info">
        <h3 v-html="entry.title"></h3>
        <div
          v-html="entry.summary"
          class="card-item__bottom__info__summary"
        ></div>
      </div>

      <div class="card-item__bottom__meta">
        <span v-html="entry.date"></span>
        <CtaItem
          colour="dark-grey"
          :label="
            entry.hasCaseStudy ? 'View the case study' : 'Charity website'
          "
          :disableLink="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CtaItem from '@/components/Cta/CtaItem';

export default {
  name: 'CardItem',
  components: {
    CtaItem
  },
  props: {
    entry: [Array, Object],
    index: Number
  },
  data: () => {
    return {
      publicPath: process.env.BASE_URL
    };
  },
  computed: {
    isCardLoaded() {
      return this.entry ? true : false;
    },
    cover() {
      return this.entry.cover ? this.entry.cover[0] : null;
    },
    placeholderCover() {
      return {
        alt: 'Charity Placeholder',
        src: `${this.publicPath}img/charity-placeholder.jpg`
      };
    },
    getCover() {
      if (!this.isCardLoaded) {
        return {
          src: this.placeholderCover.src,
          alt: 'Westminster Foundation Charity'
        };
      } else {
        return {
          src: this.cover.src,
          alt: this.cover.alt,
          srcset: this.cover.srcset
        };
      }
    },
    colourLocation() {
      if (!this.entry) return [];

      switch (this.entry.location.label) {
        case 'Westminster':
          return 'text-brand-red';

        case 'Chester':
          return 'text-brand-lightGreen';

        case 'National':
          return 'text-brand-darkerBlue';

        case 'Rural communities':
          return 'text-brand-pink';

        default:
          return null;
      }
    }
  }
};
</script>

<style lang="postcss">
.card-item {
  border-radius: 5px;
  @apply bg-brand-white;
  @apply font-ginto-regular;
  @apply flex flex-wrap;
  font-size: calc(12px + (12 - 12) * (100vw - 640px) / (1920 - 640));
  &__top {
    @apply relative;
    @apply w-full h-auto;
    &__label-top {
      @apply bg-brand-red;
      @apply p-6 px-16;
      @apply text-brand-white;
      font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
      @apply absolute;
      @apply z-50;
      border-radius: 5px 0px 5px 0px;
    }
    &__image-container {
      padding-top: calc((2 / 3) * 100%);
      @apply bg-brand-green;
      @apply relative;
    }
    img {
      border-radius: 5px 5px 0px 0px;
      @apply object-cover;
      @apply w-full h-full;
      @apply absolute top-0 left-0;
    }
    &__label-bottom {
      @apply bg-brand-white;
      @apply flex items-center justify-center;
      width: 180px;
      height: 50px;
      font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
      @apply absolute bottom-0;
      border-radius: 0px 5px 0px 0px;
    }
  }
  &__bottom {
    @apply p-16;
    @apply min-h-full;
    &__info {
      h3 {
        @apply leading-none;
        @apply mb-4;
        font-size: calc(20px + (26 - 20) * (100vw - 640px) / (1920 - 640));
        @screen sm {
          @apply mb-8;
        }
      }
    }

    &__meta {
      @apply pt-12;
      @apply flex justify-between items-center;
      @apply mt-16;
      @apply border-t border-solid border-brand-grey;
      span {
        @apply text-brand-dark-grey;
        font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
      }
      .cta-item {
        a {
          font-size: calc(12px + (16 - 12) * (100vw - 640px) / (1920 - 640));
        }
      }
    }
  }
}
</style>
